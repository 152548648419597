// #region Local Imports
import { Http } from "@Services/API/Http";
// #endregion Local Imports

// #region Interface Imports
import { DiscoverPeopleModel, MessagesModel } from "@Interfaces";
import { userErrorDummyResponse } from "@Constants";
// #endregion Interface Imports

const apiBaseUrl =
  process.env.REACT_APP_IS_PRODUCTION_API === "true"
    ? process.env.REACT_APP_API_URL
    : process.env.REACT_APP_STAGING_API_URL;
const apiBaseUrlV4 =
  process.env.REACT_APP_IS_PRODUCTION_API === "true"
    ? process.env.REACT_APP_API_URL_V4
    : process.env.REACT_APP_STAGING_API_URL_V4;
const apiBaseUrlV5 =
  process.env.REACT_APP_IS_PRODUCTION_API === "true"
    ? process.env.REACT_APP_API_URL_V5
    : process.env.REACT_APP_STAGING_API_URL_V5;

export const DiscoverPeopleService = {
  DiscoverPeople: async (
    payload: DiscoverPeopleModel.GetGETDiscoverPeoplePayload
  ): Promise<DiscoverPeopleModel.GetGETDiscoverPeopleResponse> => {
    let response: DiscoverPeopleModel.GetGETDiscoverPeopleResponse;

    const { userId, ...rest } = payload;
    let apiBaseUrlPP = "https://prod.playpal.games/api/v1";
    try {
      response =
        await Http.Request<DiscoverPeopleModel.GetGETDiscoverPeopleResponse>(
          "GET",
          apiBaseUrlPP +
            "/playPal/discover/" +
            payload.userId +
            "/" +
            payload.pageNo +
            "/" +
            payload.pageCount,
          undefined
        );
    } catch (error) {
      response = {
        response: [],
        success: false,
        message: "",
      };
    }
    return response;
  },
  GetUserFriends: async (
    payload: DiscoverPeopleModel.GetGETFriendsPayload
  ): Promise<DiscoverPeopleModel.GetGETFriendsResponse> => {
    let response: DiscoverPeopleModel.GetGETFriendsResponse;

    const { userIds } = payload;
    try {
      response = await Http.Request<DiscoverPeopleModel.GetGETFriendsResponse>(
        "POST",
        apiBaseUrlV4 + "/users/multiple",
        undefined,
        { userIds }
      );
    } catch (error) {
      response = {
        message: "",
        response: {},
        success: false,
      };
    }
    return response;
  },
};
