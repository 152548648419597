import React, { useState } from "react";
import ReactDOM from "react-dom";

import { AnimatePopup, ModalContainer, ParagraphText } from "@Styled";

// props used in Genric Popup
// -----------------
// title                -> Displays title
// description          -> Displays description
// showSingleButton     -> Handle between single and double button
// firstButtonText      -> Text on first Button
// secondButtonText     -> Text on Second Button
// showInfoIcon         -> if true the show Error(info) icon else show Success(Smile) icon
// firstButtonCallback  -> Will call on click of first button both in case of single and double button
// secondButtonCallback -> Will call on click of second button in case of double button

export const GenericPopupModal: React.FunctionComponent<{
  title?: string;
  description?: string;
  showSingleButton?: boolean;
  firstButtonText?: string;
  secondButtonText?: string;
  showInfoIcon?: boolean;
  themeColor?: string;
  firstButtonCallback?: () => void;
  secondButtonCallback?: () => void;
  firstButtonWidth?: string;
  width?: string;
}> = (props) => {
  const title = props.title ? props.title : "";
  const description = props.description ? props.description : "";
  const showSingleButton = props.showSingleButton;
  const firstButtonText = props.firstButtonText ? props.firstButtonText : "Yes";
  const secondButtonText = props.secondButtonText
    ? props.secondButtonText
    : "No";
  const showInfoIcon = props.showInfoIcon;
  const themeColor = props.showInfoIcon ? "#FF5115" : "#E95A24";
  let infoImage = showInfoIcon
    ? "/images/warning.png"
    : "/images/smily_green.png";

  const viewPort = document.getElementById("view-port-container");
  return ReactDOM.createPortal(
    <AnimatePopup animateIn={true} background="rgb(0,0,0,0.7)" zIndex={3}>
      <ModalContainer
        width={props.width}
        headerChild={
          <img
            src={infoImage}
            style={{ color: themeColor, width: "50px", height: "50px" }}
          />
        }
      >
        <div className="d-flex flex-column" style={{ marginTop: "15px" }}>
          {title.length > 0 && (
            <ParagraphText
              className="text-center font-24px"
              style={{ color: themeColor }}
            >
              {title}
            </ParagraphText>
          )}

          {description.length > 0 && (
            <ParagraphText className="text-black text-center font-16px">
              {description}
            </ParagraphText>
          )}

          <div
            className="d-flex align-items-center justify-content-center text-white"
            style={{ marginTop: "10px" }}
          >
            {showSingleButton ? (
              <div
                style={{
                  borderRadius: "27px",
                  width: props.firstButtonWidth ? "auto" : "70px",
                  height: "32px",
                  backgroundColor: themeColor,
                  padding: "10px",
                }}
                className="cursor-pointer d-flex align-items-center justify-content-center"
                onClick={() => {
                  props.firstButtonCallback && props.firstButtonCallback();
                }}
              >
                {firstButtonText}
              </div>
            ) : (
              <div className="d-flex align-items-center ">
                <div
                  style={{
                    borderRadius: "27px",
                    minWidth: "70px",
                    // width: "70px",
                    height: "32px",
                    marginRight: "30px",
                    backgroundColor: themeColor,
                    padding: "0 20px",
                  }}
                  className="cursor-pointer d-flex align-items-center justify-content-center"
                  onClick={() => {
                    props.firstButtonCallback && props.firstButtonCallback();
                  }}
                >
                  {firstButtonText}
                </div>
                <div
                  style={{
                    borderRadius: "27px",
                    minWidth: "70px",
                    // width: "70px",
                    height: "32px",
                    backgroundColor: "#9D9D9D",
                    padding: "0 20px",
                  }}
                  className="cursor-pointer d-flex align-items-center justify-content-center"
                  onClick={() => {
                    props.secondButtonCallback && props.secondButtonCallback();
                  }}
                >
                  {secondButtonText}
                </div>
              </div>
            )}
          </div>
        </div>
      </ModalContainer>
    </AnimatePopup>,
    viewPort ? viewPort : document.body
  );
};
