import React from "react";
import styled from "styled-components";

import { IPrimaryButton } from "./PrimaryButton";
import { theme } from "@Definitions/Styled";

const Container = styled.button<IPrimaryButton.IProps>`
  background-color: ${({ backgroundColor }) => {
    return backgroundColor ? backgroundColor : theme.colors.primary;
  }};
  background: ${({ background }) => {
    return background ? background : "";
  }};
  padding: ${({ padding }) => {
    return padding ? padding : "0.6rem 1.7rem";
  }};
  border-radius: ${({ borderRadius }) => {
    return borderRadius ? borderRadius : "22px";
  }};
  outline: none;
  border: ${({ border }) => {
    return border ? border : "0px";
  }};
  color: ${({ color }) => {
    return color ? color : "##1D0141 !important";
  }};
  &:focus {
    outline: none;
  }
`;

export const PrimaryButton: React.FunctionComponent<IPrimaryButton.IProps> = ({
  children,
  isActive,
  onClick,
  className,
  borderRadius,
  padding,
  backgroundColor,
  disabled,
  id,
  color,
  border,
  background,
}) => {
  return (
    <Container
      border={border}
      padding={padding}
      color={color}
      isActive={isActive}
      onClick={onClick}
      className={className}
      borderRadius={borderRadius}
      backgroundColor={backgroundColor}
      background={background}
      disabled={disabled}
      id={id}
    >
      {children}
    </Container>
  );
};
