import React, { useEffect } from "react";
import { GAME_CONFIG, GAME_ITEM, IStore } from "@Interfaces";
import ReactDOM from "react-dom";
import { AnimatePopup, BackgroundImage } from "@Styled";
import { useDispatch, useSelector } from "react-redux";
import { MultiGamePlayAction } from "@Actions/MultiGamePlayAction";
import useTheme from "../../Hooks/useTheme";
import { getBC3Color } from "@HelperFunctions";

export const GameTypeView: React.FunctionComponent<{
  show: boolean;
  setShow: (a: boolean) => void;
  onRequestMatchMaking: (a: number, b: boolean) => void;
  selectedGameTypes: GAME_CONFIG[];
}> = ({ show, setShow, onRequestMatchMaking, selectedGameTypes }) => {
  const dispatch = useDispatch();
  const theme = useSelector((state: any) => state.themeReducer.theme);
  const { selectedGameToPlay, showGameTypeView } = useSelector(
    (state: IStore) => state.multiPlayState
  );
  const setSpecificConfig = (selectedConfig: GAME_CONFIG) => {
    setShow(!show);
    dispatch(
      MultiGamePlayAction.SetSpecificSelectedConfiguration(
        selectedConfig.matchMakingTemplate
      )
    );
    dispatch(MultiGamePlayAction.SetShowGameTypeView(false));
    // dispatch(MultiGamePlayAction.SetIsHost(true));
    onRequestMatchMaking(selectedGameToPlay.gameId, true);
  };
  const resetStates = () => {
    setShow(!show);
    dispatch(MultiGamePlayAction.SetShowGameTypeView(false));
    dispatch(MultiGamePlayAction.SetSelectedGameToPlay({}));
    dispatch(MultiGamePlayAction.SetSelectedGameConfig([]));
  };
  return show && showGameTypeView
    ? ReactDOM.createPortal(
        <AnimatePopup
          animateIn={true}
          zIndex={1}
          alignItems="baseline"
          background={
            'url("/images/newDesign/BG_Design.png") center center / cover'
          }
        >
          <div
            className="hide-y-scroller"
            style={{
              overflowY: "scroll",
              height: "100%",
              backgroundColor: theme === "dark" ? "#1D1540" : "#FFFFFF",
            }}
          >
            <div
              className="d-flex align-items-center px-3"
              style={{ minHeight: "58px" }}
            >
              <button className="app-back-button" onClick={resetStates}>
                <img
                  className="mr-2"
                  height="12px"
                  width="6px"
                  src="/images/back.png"
                />
              </button>
            </div>
            <div className="text-center" style={{ marginTop: "5%" }}>
              <div className="position-relative">
                <img
                  src={selectedGameToPlay?.thumbnail}
                  alt="icon"
                  width="22%"
                  style={{ borderRadius: "50%" }}
                />
                {/*<div style={{position: 'absolute', width: '54%', left: '23%', top: '-72%', zIndex: -1}}>*/}
                <div
                  style={{
                    position: "absolute",
                    width: "45%",
                    left: "28%",
                    top: "-51%",
                    zIndex: -1,
                  }}
                >
                  <BackgroundImage
                    src={"/images/newDesign/thumbnail_glow.png"}
                    backgroundSize="cover"
                    backgroundPosition="center"
                    paddingBottom="100%"
                  />
                </div>
              </div>
              <div className="d-flex flex-wrap justify-content-between align-items-center">
                {selectedGameTypes.map((selectedType) => {
                  return (
                    <div className="px-2 pt-4 w-50" key={selectedType.id}>
                      <img
                        className="cursor-pointer"
                        onClick={() => setSpecificConfig(selectedType)}
                        src={selectedType.icon}
                        alt=""
                        width="90%"
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </AnimatePopup>,
        document.getElementById("view-port-container")!
      )
    : null;
};
