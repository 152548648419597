// #region Local Imports
import { Http } from "@Services/API/Http";
// #endregion Local Imports

// #region Interface Imports
import { GamesListModel, InviteModel } from "@Interfaces";
import { getQueryParams } from "@Services/Cookie";
// #endregion Interface Imports

const apiBaseUrl =
  process.env.REACT_APP_IS_PRODUCTION_API === "true"
    ? process.env.REACT_APP_API_URL
    : process.env.REACT_APP_STAGING_API_URL;
const apiBaseUrlV4 =
  process.env.REACT_APP_IS_PRODUCTION_API === "true"
    ? process.env.REACT_APP_API_URL_V4
    : process.env.REACT_APP_STAGING_API_URL_V4;
const apiBaseUrlV5 =
  process.env.REACT_APP_IS_PRODUCTION_API === "true"
    ? process.env.REACT_APP_API_URL_V5
    : process.env.REACT_APP_STAGING_API_URL_V5;

export const GameService = {
  GetGamesList: async (
    payload: GamesListModel.GetGETGamesListPayload
  ): Promise<GamesListModel.GetGETGamesListResponse> => {
    let response: GamesListModel.GetGETGamesListResponse;

    try {
      response = await Http.Request<GamesListModel.GetGETGamesListResponse>(
        "GET",
        apiBaseUrl + "/games_list",
        undefined
      );
    } catch (error) {
      response = {
        message: "",
        success: false,
        response: [],
      };
    }
    return response;
  },
  GetHomeBanner: async (payload: any): Promise<any> => {
    let response: any;
    let apiBaseUrl = "https://prod.playpal.games/api/v1"
    try {
      response = await Http.Request<any>(
        "GET",
        apiBaseUrl + "/playPal/banners",
        undefined
      );
    } catch (error) {
      response = {
        message: "",
        success: false,
        response: [],
      };
    }
    return response;
  },
  GetSetReminder: async (payload: any): Promise<any> => {
    let response: any;
    let apiBaseUrl ="https://prod.playpal.games/api"
    try {
      response = await Http.Request<any>(
        "POST",
        apiBaseUrl + "/tournament/setReminder",
        undefined,
        { ...payload }
      );
    } catch (error) {
      response = {
        message: "",
        success: false,
        response: [],
      };
    }
    return response;
  },
  GetAppDesignGamesList: async (
    payload: GamesListModel.GetGETGamesListPayload
  ): Promise<GamesListModel.GetGETGamesListResponse> => {
    let response: GamesListModel.GetGETGamesListResponse;

    try {
      response = await Http.Request<GamesListModel.GetGETGamesListResponse>(
        "GET",
        apiBaseUrl +
          "/gameslist/" +
          (payload.pageNo || 1) +
          "/" +
          (payload.itemCount || 10),
        {
          configVersion: payload.configVersion || 0,
          itemCount: payload.itemCount || 10,
          pageNo: payload.pageNo || 1,
        }
      );
    } catch (error) {
      response = {
        message: "",
        success: false,
        response: [],
      };
    }
    return response;
  },
  RequestMatchMakingg: async (
    payload: GamesListModel.GetPOSTMatchMakingRequestPayload
  ): Promise<GamesListModel.GetPOSTMatchMakingRequestResponse> => {
    let response: GamesListModel.GetPOSTMatchMakingRequestResponse;

    try {
      response =
        await Http.Request<GamesListModel.GetPOSTMatchMakingRequestResponse>(
          "POST",
          apiBaseUrlV4 + "/playgame",
          undefined,
          { ...payload }
        );
    } catch (error) {
      response = {
        success: false,
        response: {},
        message: "",
      };
    }
    return response;
  },
  RequestMatchMaking: async (
    payload: GamesListModel.GetPOSTMatchMakingRequestPayload
  ): Promise<GamesListModel.GetPOSTMatchMakingRequestResponse> => {
    let response: GamesListModel.GetPOSTMatchMakingRequestResponse;

    try {
      response =
        await Http.Request<GamesListModel.GetPOSTMatchMakingRequestResponse>(
          "POST",
          apiBaseUrlV4 + "/game/play",
          undefined,
          { ...payload }
        );
    } catch (error) {
      response = {
        success: false,
        response: {},
        message: "",
      };
    }
    return response;
  },
  GetMatch: async (
    payload: GamesListModel.GetGETMatchPayload
  ): Promise<GamesListModel.GetGETMatchResponse> => {
    let response: GamesListModel.GetGETMatchResponse;
    try {
      response = await Http.Request<GamesListModel.GetGETMatchResponse>(
        "GET",
        apiBaseUrlV4 + "/match/get",
        { matchId: payload.matchId }
      );
    } catch (error) {
      response = {
        success: false,
        response: {},
        message: "",
      };
    }
    return response;
  },
  GetGameDocStr: async (
    payload: GamesListModel.GetGETGameDocStrPayload
  ): Promise<GamesListModel.GetGETGameDocStrResponse> => {
    let response: GamesListModel.GetGETGameDocStrResponse;

    // const gamePort = payload.game.url.split(":")[2].split("/")[0];
    const gameUrl = payload.game.url + "/index.html";
    try {
      response =
        await Http.GetHTMLText<GamesListModel.GetGETGameDocStrResponse>(
          "GET",
          gameUrl,
          // "http://192.168.5.196:8091/?staticPath=http://192.168.5.196:8091",
          { timestamp: +Date.now() }
        );
    } catch (error) {
      response = {
        response: "",
      };
    }
    return response;
  },
  // CancelMatchMaking: async (
  //     payload: GamesListModel.GetPOSTCancelMatchMakingPayload
  // ): Promise<GamesListModel.GetPOSTCancelMatchMakingResponse> => {
  //     let response: GamesListModel.GetPOSTCancelMatchMakingResponse;
  //
  //     try {
  //         response = await Http.Request<GamesListModel.GetPOSTCancelMatchMakingResponse>(
  //             "POST",
  //             apiBaseUrl + "/exitgame",
  //             undefined,
  //             { ...payload }
  //         );
  //     } catch (error) {
  //         response = {
  //             response: [],
  //             success: false,
  //             message: ''
  //         }
  //     }
  //     return response;
  // },
  CancelMatchMaking: async (
    payload: GamesListModel.GetPOSTCancelMatchMakingPayload
  ): Promise<GamesListModel.GetPOSTCancelMatchMakingResponse> => {
    let response: GamesListModel.GetPOSTCancelMatchMakingResponse;

    try {
      response =
        await Http.Request<GamesListModel.GetPOSTCancelMatchMakingResponse>(
          "POST",
          apiBaseUrlV4 + "/game/exit",
          undefined,
          { ...payload }
        );
    } catch (error) {
      response = {
        response: [],
        success: false,
        message: "",
      };
    }
    return response;
  },
  LeaveMatch: async (
    payload: GamesListModel.GetPOSTLeaveMatchPayload
  ): Promise<InviteModel.GetPOSTAcceptRejectMatchInviteResponse> => {
    let response: InviteModel.GetPOSTAcceptRejectMatchInviteResponse;

    try {
      response =
        await Http.Request<InviteModel.GetPOSTAcceptRejectMatchInviteResponse>(
          "POST",
          apiBaseUrlV4 + "/game/leaveGame",
          undefined,
          { ...payload }
        );
    } catch (error) {
      response = {
        response: {},
        success: false,
        message: "",
      };
    }
    return response;
  },
  SaveMatchResult: async (
    payload: GamesListModel.GetPOSTSaveResultPayload
  ): Promise<GamesListModel.GetPOSTSaveResultResponse> => {
    let response: GamesListModel.GetPOSTSaveResultResponse;

    try {
      response = await Http.Request<GamesListModel.GetPOSTSaveResultResponse>(
        "POST",
        apiBaseUrl + "/saveresult",
        undefined,
        { ...payload }
      );
    } catch (error) {
      response = {
        message: "",
        response: {},
        success: false,
      };
    }
    return response;
  },
  PlayAgain: async (
    payload: GamesListModel.GetPOSTPlayAgainPayload
  ): Promise<GamesListModel.GetPOSTPlayAgainResponse> => {
    let response: GamesListModel.GetPOSTPlayAgainResponse;

    try {
      response = await Http.Request<GamesListModel.GetPOSTPlayAgainResponse>(
        "POST",
        apiBaseUrl + "/playAgain",
        undefined,
        { ...payload }
      );
    } catch (error) {
      response = {
        message: "",
        success: false,
        response: {},
      };
    }
    return response;
  },

  InviteToPlayAgain: async (
    payload: GamesListModel.GetPOSTInvitePlayAgainPayload
  ): Promise<GamesListModel.GetPOSTInvitePlayAgainResponse> => {
    let response: GamesListModel.GetPOSTInvitePlayAgainResponse;

    try {
      response =
        await Http.Request<GamesListModel.GetPOSTInvitePlayAgainResponse>(
          "POST",
          apiBaseUrlV4 + "/game/playAgain",
          undefined,
          { ...payload }
        );
    } catch (error) {
      response = {
        message: "",
        success: false,
        response: { matchId: "" },
      };
    }
    return response;
  },

  CancelPlayAgain: async (
    payload: GamesListModel.GetPOSTCancelPlayAgainPayload
  ): Promise<GamesListModel.GetPOSTCancelPlayAgainResponse> => {
    let response: GamesListModel.GetPOSTCancelPlayAgainResponse;

    try {
      response =
        await Http.Request<GamesListModel.GetPOSTCancelPlayAgainResponse>(
          "POST",
          apiBaseUrl + "/cancelPlayAgain",
          undefined,
          { ...payload }
        );
    } catch (error) {
      response = {
        response: {},
        message: "",
        success: false,
      };
    }
    return response;
  },
  PlayGameOnRequest: async (
    payload: GamesListModel.GetPOSTPlayGameOnRequestPayload
  ): Promise<GamesListModel.GetPOSTPlayGameOnRequestResponse> => {
    let response: GamesListModel.GetPOSTPlayGameOnRequestResponse;

    try {
      response =
        await Http.Request<GamesListModel.GetPOSTPlayGameOnRequestResponse>(
          "POST",
          apiBaseUrlV4 + "/game/start",
          undefined,
          { ...payload }
        );
    } catch (error) {
      response = {
        response: {},
        message: "",
        success: false,
      };
    }
    return response;
  },
  SearchGames: async (
    payload: GamesListModel.GetGETSearchGamesPayload
  ): Promise<GamesListModel.GetGETSearchGamesResponse> => {
    let response: GamesListModel.GetGETSearchGamesResponse;

    try {
      response = await Http.Request<GamesListModel.GetGETSearchGamesResponse>(
        "GET",
        apiBaseUrl + "/games/search" + getQueryParams(payload),
        undefined
      );
    } catch (error) {
      response = {
        success: false,
        message: "Api failed",
        response: [],
      };
    }
    return response;
  },
  SearchGamesAsync: async (
    payload: GamesListModel.GetGETSearchGamesPayload
  ): Promise<GamesListModel.GetGETSearchGamesResponse> => {
    let response: GamesListModel.GetGETSearchGamesResponse;

    try {
      response = await Http.Request<GamesListModel.GetGETSearchGamesResponse>(
        "GET",
        apiBaseUrl + "/games/search",
        {
          pageNo: payload.pageNo,
          name: payload.name,
        }
      );
    } catch (error) {
      response = {
        success: false,
        message: "Api failed",
        response: [],
      };
    }
    return response;
  },
  GetGamesHistory: async (
    payload: GamesListModel.GetGETGameHistoryPayload
  ): Promise<GamesListModel.GetGETGameHistoryResponse> => {
    let response: GamesListModel.GetGETGameHistoryResponse;

    try {
      response = await Http.Request<GamesListModel.GetGETGameHistoryResponse>(
        "GET",
        apiBaseUrl + "/playedgames/" + payload.userId,
        undefined
      );
    } catch (error) {
      response = {
        success: false,
        message: "Api failed",
        response: [],
      };
    }

    return response;
  },
  GetGameResultFromServer: async (payload: any): Promise<any> => {
    let response: any;

    try {
      response = await Http.Request<any>(
        "GET",
        apiBaseUrlV5 + "/getresult/" + payload.matchId + "/" + payload.userId,
        undefined
      );
    } catch (error) {
      response = {
        success: false,
        message: "Api failed",
        response: [],
      };
    }
    return response;
  },
};
