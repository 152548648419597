import React from "react";
import "./Terms.css";
import { useHistory, useLocation } from "react-router-dom";

const Terms: React.FunctionComponent<{
  setLoginWithMobile: (a: boolean) => void;
}> = ({ setLoginWithMobile }) => {
  const history = useHistory();
  const location = useLocation();
  const handler = () => {
    history.push("/login");
    // setLoginWithMobile(true);
  };

  const hideLoader = () => {
    document.getElementById("loader")!.style.display = "none";
    document.getElementById("iframe")!.style.opacity = "1";
  };

  return (
    <>
      <div onClick={handler} className="routeContainer">
        <img
          style={{
            cursor: "pointer",
          }}
          className="mr-2 "
          height="12px"
          width="6px"
          src="/images/back.png"
        />
        <span className="ml-2">Terms And Conditions</span>
      </div>
      <div className="iframeContainer">
        <div id="loader" style={{ position: "absolute" }}>
          <img src="/continue_loader_1.gif" width={"50px"} />
        </div>
        <iframe
          onLoad={hideLoader}
          id="iframe"
          style={{ backgroundColor: "white", opacity: 0 }}
          //@ts-ignore
          src={location.state.url}
          width="100%"
          height="100%"
          frameBorder="0"
        ></iframe>
      </div>
    </>
  );
};

export default Terms;
