export const ActionConsts = {
  Home: {
    GetRecentPlayedGamesSuccess: "GetRecentPlayedGamesSuccess",
    GetGamesListSuccess: "GetGamesListSuccess",
    GetGamesListError: "GetGamesListError",
    SetUserHasLowBalance: "SetUserHasLowBalance",
    GameListLoaded: "GameListLoaded",
    GetAppDesignGamesListSuccess: "GetAppDesignGamesListSuccess",
    GetAppDesignGamesListAppend: "GetAppDesignGamesListAppend",
    GetAppDesignGamesListError: "GetAppDesignGamesListError",
    SetShowGameBoard: "SetShowGameBoard",
    SetUserHasNoName: "SetUserHasNoName",
    BannerData: "BannerData",
    SetGameMessages: "SetGameMessages",
    DeleteGameMessage: "DeleteGameMessage",
  },
  Authentication: {
    LogoutUserSuccess: "LogoutUserSuccess",
    UserLoginSuccess: "UserLoginSuccess",
    UpdateCheckStatusResponse: "UpdateCheckStatusResponse",
    UserLoginError: "UserLoginError",
    ClearLoginError: "ClearLoginError",
    SetAfterLoginRoute: "SetAfterLoginRoute",
    SetFirebaseConfigSuccess: "SetFirebaseConfigSuccess",
    SetFirebaseConfigError: "SetFirebaseConfigError",
    UpdateBlockKeysInSession: "UpdateBlockKeysInSession",
    GetUserSuccess: "GetUserSuccess",
    GetUserError: "GetUserError",
    SetUserPackageDetails: "SetUserPackageDetails",
    SetWebVersion: "SetWebVersion",
    SetUTMParams: "SetUTMParams",
    SetLogoutUser: "SetLogoutUser",
    ShowPaywall: "ShowPaywall",
    GetNewFriendReqArray: "GetNewFriendReqArray",
    SetPaywallData: "SetPaywallData",
    SetPaywallSkipStatus: "SetPaywallSkipStatus",
    UserHasSkippedPaywall: "UserHasSkippedPaywall",
    SetSingleServiceData: "SetSingleServiceData",
    UpdateFirebaseConfig: "UpdateFirebaseConfig",
    SetVerificationConfigData: "SetVerificationConfigData",
    SetLoginPaymentMethod: "SetLoginPaymentMethod",
    SetUserFriendsData: "SetUserFriendsData",
    SetGameConfigJson: "SetGameConfigJson",
    SetInviRedirectionUrl: "SetInviRedirectionUrl",
    SetUserTournament: "SetUserTournament",
  },
  Messages: {
    GetFriendsListSuccess: "GetFriendsListSuccess",
    GetNewFriendsRequest: "GetNewFriendsRequest",
    GetFriendsListError: "GetFriendsListError",
    GetStrangerListSuccess: "GetStrangerListSuccess",
    GetStrangerListError: "GetStrangerListError",
    UpdateUnseenMessageInFotter: "UpdateUnseenMessageInFotter",
    SaveRooms: "SaveRooms",
  },
  Conversation: {
    GetConversationSuccess: "GetConversationSuccess",
    GetConversationError: "GetConversationError",
    SetConversationSuccess: "SetConversationSuccess",
    UpdateConversationSuccess: "UpdateConversationSuccess",
    SetConversationOtherUser: "SetConversationOtherUser",
    ClearConversation: "ClearConversation",
    UserFirebaseConnectivityStatus: "UserFirebaseConnectivityStatus",
    SendMessageOnServerSuccess: "SendMessageOnServerSuccess",
    UpdateTypingUserList: "UpdateTypingUserList",
    UpdateChatRoomList: "UpdateChatRoomList",
  },
  Invite: {
    ClearSearchUser: "ClearSearchUser",
    GetSearchUsersSuccess: "GetSearchUsersSuccess",
    GetSearchUsersError: "GetSearchUsersError",
    SetFacebookSyncedFriends: "SetFacebookSyncedFriends",
  },
  Settings: {
    SetSelectedLanguage: "SetSelectedLanguage",
    UpdateRecentlyPlayedGame: "UpdateRecentlyPlayedGame",
    UpdateGameHistorySuccess: "UpdateGameHistorySuccess",
    UpdateGameHistoryError: "UpdateGameHistoryError",
    SetTermsAndConditionModal: "SetTermsAndConditionModal",
    SetInstallPWAModal: "SetInstallPWAModal",
    UserGeoLocation: "UserGeoLocation",
    LoginParams: "LoginParams",
    SetCallFunctionalityShowed: "SetCallFunctionalityShowed",
  },
  LeaderBoard: {
    GetLeaderBoardSuccess: "GetLeaderBoardSuccess",
    GetLeaderBoardError: "GetLeaderBoardError",
  },
  Tournament: {
    GetTournamentSuccess: "GetTournamentSuccess",
    GetTournamentError: "GetTournamentError",
    GetTournamentHistorySuccess: "GetTournamentHistorySuccess",
    GetTournamentHistoryError: "GetTournamentHistoryError",
    GetTournamentClaimHistorySuccess: "GetTournamentClaimHistorySuccess",
    GetTournamentClaimHistoryError: "GetTournamentClaimHistoryError",
    SetSelectedTournament: "SetSelectedTournament",
    SetIsTournamentMatch: "SetIsTournamentMatch",
    GetTournamentLeaderboardSuccess: "GetTournamentLeaderboardSuccess",
    GetTournamentLeaderboardFail: "GetTournamentLeaderboardFail",
    ClaimRewardSuccess: "ClaimRewardSuccess",
    ClaimRewardFail: "ClaimRewardFail",
    TournamentExpireSuccess: "TournamentExpireSuccess",
    TournamentExpireFail: "TournamentExpireFail",
    SetTournamentNotificationData: "SetTournamentNotificationData",
  },
  ExclusiveFeatures: {
    SetExclusiveFeature: "SetExclusiveFeature",
  },
  MultiPlayerGame: {
    SetSelectedGameConfig: "SetSelectedGameConfig",
    SetSelectedGameToPlay: "SetSelectedGameToPlay",
    SetSpecificSelectedConfiguration: "SetSpecificSelectedConfiguration",
    SetMatchObject: "SetMatchObject",
    SetIsHost: "SetIsHost",
    ResetGameConfigAndMatchObject: "ResetGameConfigAndMatchObject",
    SetShowGameTypeView: "SetShowGameTypeView",
  },
};
