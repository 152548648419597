// import { useHistory } from "react-router-dom";
// #region Local Imports
import { ActionConsts } from "@Definitions";
import flattenDeep from "lodash/flattenDeep";
import { SUB_BUNDLES } from "@Services/API/PayWall/SubscriptionBundles";
// #endregion Local Imports

// #region Interface Imports
import {
  IAction,
  IPersistState,
  USER_SESSION,
  GAME_ITEM,
  IHomePage,
  GAME_CATEGORY,
  GAME_HISTORY,
  USER,
  CONVERSATION_MESSAGE,
  CLAIM_HISTORY,
  TOURNAMENT_HOME,
} from "@Interfaces";
import { MessageType } from "@Constants";
import storage from "redux-persist/lib/storage";
import chatManager from "@Services/ChatManager/ChatManager";
import Cookies from "js-cookie";
// #endregion Interface Imports

const INITIAL_STATE: IPersistState.IStateProps = {
  session: <USER_SESSION>{},
  sessionID: "",
  redirectAfterLoginRoute: "/",
  gamesList: new Array<GAME_CATEGORY>(),
  conversationOtherUser: <USER_SESSION>{},
  multiplayerGamesList: new Array<GAME_ITEM>(),
  selectedLanguage: "en",
  recentlyPlayedGameIds: [],
  facebookSyncedFriends: new Array<USER_SESSION>(),
  recentlyPlayedGames: new Array<GAME_HISTORY>(),
  appendListLoaded: false,
  userSelectedLanguage: false,
  TermsAndCondition: false,
  InstallPWA: undefined,
  userPackageDetails: {},
  userHasNoName: false,
  currentWebVersion: "",
  userLoggedout: false,
  persistFirebaseConfiguration: { regions: [], country: {}, v: 0 },
  loginSequence: "",
  showPaywall: false,
  userHasSkippedPaywall: false,
  hitCheckSubscription: false,
  showSkipOnPaywall: true,
  paymentBundles: new Array<Array<SUB_BUNDLES>>(),
  loginPaymentMethod: "",
  showedCallFunctionality: false,
  inviRedirectionUrl: "",
  userFriends: new Array<USER>(),
  gameConfigJson: "",
  chatMessages: {
    values: new Array<CONVERSATION_MESSAGE>(),
    paginationNo: 0,
    emptyPaginationNo: 999,
  },
  rooms: [],
  wallet: new Array<CLAIM_HISTORY>(),
  recentPlayed: new Array<GAME_ITEM>(),
  bannerData: null,
};

export const PersistReducer = (
  state = INITIAL_STATE,
  action: IAction<
    IPersistState.Actions.ISetSession &
      IPersistState.Actions.ISetUserCheckStatus &
      IHomePage.Actions.IMapGamesListResponse &
      IPersistState.Actions.ISetConversationOtherUser &
      IPersistState.Actions.ISetSelectedLanguage &
      IPersistState.Actions.ISetUpdateRecentlyPlayedGameIds &
      IPersistState.Actions.IMapConversationMessages &
      IPersistState.Actions.ISetUpdatFacebookSyncedFriends &
      IPersistState.Actions.ISetTournaments & {
        key: string;
        val: string[];
      } & any[]
  >
) => {
  if ((window as any).isLocalStorageEmpty) {
    return INITIAL_STATE;
  }
  switch (action.type) {
    case ActionConsts.Settings.UpdateGameHistorySuccess: {
      return Object.assign({}, state, {
        recentlyPlayedGames: action.payload!,
      });
    }
    case ActionConsts.Settings.UpdateGameHistoryError: {
      return Object.assign({}, state, {
        recentlyPlayedGames: [],
      });
    }
    case ActionConsts.Authentication.UpdateBlockKeysInSession: {
      const param = action.payload!;

      return Object.assign({}, state, {
        session: {
          ...state.session,
          [param.key]: param.val,
        },
      });
    }
    case ActionConsts.Authentication.UpdateBlockKeysInSession: {
      const param = action.payload!;

      return Object.assign({}, state, {
        session: {
          ...state.session,
          [param.key]: param.val,
        },
      });
    }
    case ActionConsts.Invite.SetFacebookSyncedFriends: {
      const { facebookFriends } = action.payload!;

      return Object.assign({}, state, {
        facebookSyncedFriends: facebookFriends,
      });
    }
    case ActionConsts.Settings.UpdateRecentlyPlayedGame: {
      const { gameId } = action.payload!;

      if (
        state.recentlyPlayedGameIds.filter((id: number) => {
          return id === gameId;
        }).length <= 0
      ) {
        return Object.assign({}, state, {
          recentlyPlayedGameIds: [...state.recentlyPlayedGameIds, gameId],
        });
      } else {
        return state;
      }
    }
    case ActionConsts.Authentication.LogoutUserSuccess: {
      storage.removeItem("rooms");
      chatManager.clearChatRooms();
      Cookies.remove("sb-closed");
      return action.type === ActionConsts.Authentication.LogoutUserSuccess
        ? {
            ...INITIAL_STATE,
            persistFirebaseConfiguration: {
              ...state.persistFirebaseConfiguration,
            },
            userLoggedout: true,
            gamesList: [...state.gamesList],
          }
        : state;
    }
    case ActionConsts.Authentication.SetLogoutUser: {
      return Object.assign({}, state, {
        userLoggedout: action.payload,
      });
    }
    case ActionConsts.Authentication.SetWebVersion: {
      return Object.assign({}, state, {
        currentWebVersion: action.payload,
      });
    }
    case ActionConsts.Settings.SetSelectedLanguage: {
      const { language, userSelectedLanguage } = action.payload!;
      if (userSelectedLanguage) {
        return Object.assign({}, state, {
          selectedLanguage: language,
          userSelectedLanguage: true,
        });
      }
      return Object.assign({}, state, {
        selectedLanguage: language,
      });
    }
    // case ActionConsts.Conversation.SetConversationOtherUser: {
    //   const { otherUser } = action.payload!;
    //   return Object.assign({}, state, {
    //     conversationOtherUser: otherUser,
    //   });
    // }
    case ActionConsts.Home.GetGamesListSuccess: {
      const { gamesList } = action.payload!;

      const _GAME_LIST = gamesList;
      // const _GAME_LIST = [gamesList[1], gamesList[gamesList.length - 1], gamesList[gamesList.length - 2]];
      return Object.assign({}, state, {
        gamesList: _GAME_LIST,
        multiplayerGamesList: flattenDeep(
          _GAME_LIST.map((gameCategory: GAME_CATEGORY) => {
            return gameCategory.games.filter((game) => {
              return game.multiplayer;
            });
          })
        ),
      });
      // return Object.assign({}, state, {
      //     gamesList: gamesList.reverse(),
      //     multiplayerGamesList: flattenDeep(gamesList.map((gameCategory: GAME_CATEGORY)=> {
      //         return gameCategory.games.filter((game) => {
      //             return game.multiplayer
      //         })
      //     }))
      // });
    }
    case ActionConsts.Home.GetAppDesignGamesListSuccess: {
      const { gamesList } = action.payload!;

      const _GAME_LIST = gamesList;
      return Object.assign({}, state, {
        gamesList: _GAME_LIST,
        multiplayerGamesList: flattenDeep(
          _GAME_LIST.map((gameCategory: GAME_CATEGORY) => {
            return gameCategory.games.filter((game) => {
              return game.multiplayer;
            });
          })
        ),
      });
    }
    case ActionConsts.Home.GetAppDesignGamesListAppend: {
      const { gamesList } = action.payload!;
      let singleGames = [...state.gamesList];
      let newCatAdded = false;
      let length = singleGames.length;
      if (gamesList && gamesList.length) {
        gamesList.forEach((newItem: GAME_CATEGORY) => {
          singleGames.forEach((item: GAME_CATEGORY, i: number) => {
            if (item.name === newItem.name) {
              item.games.push(...newItem.games);
              newCatAdded = true;
            }
            if (i === singleGames.length - 1 && !newCatAdded) {
              singleGames.push(newItem);
              newCatAdded = false;
            }
          });
        });
      }
      //const _GAME_LIST = gamesList;
      return Object.assign({}, state, {
        gamesList: singleGames,
        appendListLoaded: gamesList && gamesList.length === 0,
      });
    }
    case ActionConsts.Authentication.SetAfterLoginRoute: {
      return Object.assign({}, state, {
        redirectAfterLoginRoute: action.payload,
      });
    }
    case ActionConsts.Authentication.UserLoginSuccess: {
      let { session } = action.payload!;
      session.purchaseStatus = state.session?.purchaseStatus;
      return Object.assign({}, state, {
        session: session,
        sessionID:
          (window as any).IS_DIRECT_LOGIN ||
          (window as any).PIN_FLOW ||
          (window as any).SOCIAL_LOGIN
            ? session.sessionId
            : state.sessionID,
        // state.sessionID == undefined || state.sessionID == ""
        //   ? session.sessionId
        //   : state.sessionID,
        userLoggedout: false,
        hitCheckSubscription: !state.hitCheckSubscription,
        recentPlayed: session.recentPlayed,
      });
    }
    case ActionConsts.Authentication.SetInviRedirectionUrl: {
      return Object.assign({}, state, {
        inviRedirectionUrl: action.payload,
      });
    }
    case ActionConsts.Authentication.UpdateCheckStatusResponse: {
      let { purchaseStatus } = action.payload!;
      return Object.assign({}, state, {
        session: { ...state.session, purchaseStatus: purchaseStatus },
      });
    }
    case ActionConsts.Settings.SetTermsAndConditionModal: {
      return Object.assign({}, state, {
        // TermsAndCondition: action.payload,
        session: { ...state.session, tncAccepted: action.payload },
      });
    }
    case ActionConsts.Settings.SetInstallPWAModal: {
      return Object.assign({}, state, {
        InstallPWA: action.payload,
      });
    }
    case ActionConsts.Authentication.SetUserPackageDetails: {
      // let { session } = action.payload!;

      return Object.assign({}, state, {
        userPackageDetails: action.payload,
      });
    }
    case ActionConsts.Home.SetUserHasNoName: {
      return Object.assign({}, state, {
        userHasNoName: action.payload,
      });
    }
    case ActionConsts.Home.BannerData: {
      return Object.assign({}, state, {
        bannerData: action.payload,
      });
    }
    case ActionConsts.Authentication.SetFirebaseConfigSuccess: {
      return Object.assign({}, state, {
        persistFirebaseConfiguration: {
          ...state.persistFirebaseConfiguration,
          ...action.payload,
        },
        session: { ...state.session, configUpdated: false },
      });
    }
    case ActionConsts.Settings.LoginParams: {
      return Object.assign({}, state, {
        loginSequence: action.payload,
      });
    }
    case ActionConsts.Authentication.SetPaywallData: {
      const data = action.payload as any;
      return Object.assign({}, state, {
        paymentBundles: data.paymentTypes,
      });
    }
    case ActionConsts.Authentication.SetSingleServiceData: {
      const data = action.payload as any;

      let isDataPushedInArray = false;
      let dublicateArray = state.paymentBundles;

      let outerServiceObject = data.data;
      let service: SUB_BUNDLES = outerServiceObject.services;

      service.numLimit = outerServiceObject.numLimit;
      service.operatorCode = outerServiceObject.operatorCode;
      service.operatorImage = outerServiceObject.operatorImage;
      service.titleAr = data.titleAr;
      service.titleEng = data.titleEng;
      service.paymentType = service.paymentType.toLowerCase();
      //@ts-ignore
      state.paymentBundles.forEach((payment, index) => {
        if (
          payment.length > 0 &&
          payment[0].package.toLowerCase() === service.package?.toLowerCase()
        ) {
          isDataPushedInArray = true;
          dublicateArray[index].push(service);
        }
      });

      if (!isDataPushedInArray) dublicateArray.push([service]);

      return Object.assign({}, state, {
        paymentBundles: dublicateArray,
      });
    }
    case ActionConsts.Authentication.SetLoginPaymentMethod: {
      return Object.assign({}, state, {
        loginPaymentMethod: action.payload,
      });
    }
    case ActionConsts.Authentication.SetUserFriendsData: {
      return Object.assign({}, state, {
        userFriends: action.payload,
      });
    }

    case ActionConsts.Authentication.SetUserTournament: {
      const { tournaments } = action.payload!;
      console.log("hellowwwwwwwwwwwwww", tournaments);
      return Object.assign({}, state, {
        session: { ...state.session, tournaments: tournaments },
      });
    }

    case ActionConsts.Authentication.SetGameConfigJson: {
      return Object.assign({}, state, {
        gameConfigJson: action.payload,
      });
    }
    case ActionConsts.Authentication.ShowPaywall:
      return Object.assign({}, state, { showPaywall: action.payload });
    case ActionConsts.Authentication.SetPaywallSkipStatus:
      return Object.assign({}, state, { showSkipOnPaywall: action.payload });
    case ActionConsts.Settings.SetCallFunctionalityShowed:
      return Object.assign({}, state, {
        showedCallFunctionality: action.payload,
      });
    case ActionConsts.Authentication.UserHasSkippedPaywall:
      return Object.assign({}, state, {
        userHasSkippedPaywall: action.payload,
      });
    case ActionConsts.Conversation.ClearConversation: {
      return Object.assign({}, state, {
        chatMessage: {
          values: [],
          paginationNo: 0,
          emptyPaginationNo: 999,
        },
      });
    }
    case ActionConsts.Conversation.SetConversationSuccess: {
      console.log("action.payload", action.payload);
      if (action.payload!.length === 1) {
        let isInvite = false;
        const inviteMessage = (action.payload as any)[0];
        try {
          isInvite = inviteMessage.type === MessageType.INVITE;
        } catch (e) {
          isInvite = false;
        }
        if (
          isInvite &&
          inviteMessage.previousMid &&
          state.chatMessages.values.length
        ) {
          const currentChatMessages = [...state.chatMessages.values];
          const updatedChateMessages: any[] = [];
          currentChatMessages.map((message: any) => {
            if (message._id === inviteMessage.previousMid)
              updatedChateMessages.push(action.payload![0]);
            else updatedChateMessages.push(message);
          });
          return Object.assign({}, state, {
            chatMessages: {
              values: updatedChateMessages,
              paginationNo: state.chatMessages.paginationNo,
              emptyPaginationNo: state.chatMessages.emptyPaginationNo,
            },
          });
        }
        if (!isInvite || (isInvite && !inviteMessage.previousMid)) {
          return Object.assign({}, state, {
            chatMessages: {
              values: [...state.chatMessages.values, ...action.payload!],
              paginationNo: state.chatMessages.paginationNo,
              emptyPaginationNo: state.chatMessages.emptyPaginationNo,
            },
          });
        }
        if (
          isInvite &&
          inviteMessage.previousMid &&
          state.chatMessages.values.length === 0
        ) {
          return Object.assign({}, state, {
            chatMessages: {
              values: [...action.payload!],
              paginationNo: state.chatMessages.paginationNo,
              emptyPaginationNo: state.chatMessages.emptyPaginationNo,
            },
          });
        }
      } else {
        const allMessages = [...action.payload!];
        const updatedChatMessages: any[] = [];
        allMessages.map((message: any) => {
          if (message.type !== MessageType.INVITE) {
            if (
              updatedChatMessages.findIndex((chatMessage) => {
                return chatMessage?._id == message._id;
              }) < 0
            )
              updatedChatMessages.push(message);
            else console.log("Dublicate Message Found", message);
          } else {
            if (!message.previousMid) {
              const newMessage = allMessages.filter((m: any) => {
                return JSON.parse(m.message).previousMid === message._id;
              });
              if (newMessage.length) updatedChatMessages.push(newMessage[0]);
              else updatedChatMessages.push(message);
            }
          }
        });
        return Object.assign({}, state, {
          chatMessages: {
            values: updatedChatMessages,
            paginationNo: state.chatMessages.paginationNo,
            emptyPaginationNo: state.chatMessages.emptyPaginationNo,
          },
        });
      }
      break;
    }
    case ActionConsts.Conversation.GetConversationSuccess: {
      const { chatMessages, page } = action.payload!;

      if (!page) {
        return Object.assign({}, state, {
          chatMessages: {
            values: chatMessages,
            paginationNo: page + 1,
            emptyPaginationNo: chatMessages.length
              ? state.chatMessages.emptyPaginationNo
              : page,
          },
        });
      }

      if (chatMessages.length) {
        return Object.assign({}, state, {
          chatMessages: {
            values: [...state.chatMessages.values, ...chatMessages],
            paginationNo: page + 1,
            emptyPaginationNo: state.chatMessages.emptyPaginationNo,
          },
        });
      } else {
        return Object.assign({}, state, {
          chatMessages: {
            emptyPaginationNo: page,
            values: state.chatMessages.values,
            paginationNo: page,
          },
        });
      }
    }
    case ActionConsts.Conversation.UpdateConversationSuccess: {
      let currentChatMessages = [...state.chatMessages.values];
      currentChatMessages.pop();
      // currentChatMessages.push(action.payload  );
      return Object.assign({}, state, {
        chatMessages: {
          values: [...currentChatMessages, ...action.payload!],
          paginationNo: state.chatMessages.paginationNo,
          emptyPaginationNo: state.chatMessages.emptyPaginationNo,
        },
      });
    }
    case ActionConsts.Messages.SaveRooms: {
      return Object.assign({}, state, {
        rooms: action.payload,
      });
    }
    default:
      return state;
  }
};
