// #region Global Imports
import { Dispatch } from "redux";
// #endregion Global Imports

// #region Local Imports
import { ActionConsts } from "@Definitions";
import { TournamentHomeService } from "@Services/API/TournamentHome";
// #endregion Local Imports

// #region Interface Imports
import { ITournamentPage } from "@Interfaces/Pages/Tournament";
import { TOURNAMENT_HOME, TOURNAMENT_NOTIFICATION_DATA } from "@Interfaces";
import { store } from "@Redux";
// #endregion Interface Imports

export const TournamentActions = {
  SetIsTournamentMatch: (payload: boolean) => async (dispatch: Dispatch) => {
    dispatch({
      payload: payload,
      type: ActionConsts.Tournament.SetIsTournamentMatch,
    });
  },

  GetTournamentData:
    (payload: ITournamentPage.Actions.IGetGETTournamentHistoryPayload) =>
    async (dispatch: Dispatch) => {
      const result = await TournamentHomeService.GetTournamentData(payload);

      dispatch({
        payload: {
          activeTournaments:
            result.response && result.success
              ? result.response.activeTournaments
              : [],
          banners:
            result.response && result.success ? result.response.upComing : [],
        },
        type:
          result.response && result.success
            ? ActionConsts.Tournament.GetTournamentSuccess
            : ActionConsts.Tournament.GetTournamentError,
      });
    },

  GetTournamentHistory:
    (payload: ITournamentPage.Actions.IGetGETTournamentHistoryPayload) =>
    async (dispatch: Dispatch) => {
      const result = await TournamentHomeService.GetTournamentHistoryData(
        payload
      );
      dispatch({
        payload: result.response && result.success ? result.response : [],
        type:
          result.response && result.success
            ? ActionConsts.Tournament.GetTournamentHistorySuccess
            : ActionConsts.Tournament.GetTournamentHistoryError,
      });
    },

  GetTournamentClaimHistory:
    (payload: ITournamentPage.Actions.IGetGETTournamentHistoryPayload) =>
    async (dispatch: Dispatch) => {
      const result = await TournamentHomeService.GetTournamentClaimHistoryData(
        payload
      );
      dispatch({
        payload: result.response && result.success ? result.response : [],
        type:
          result.response && result.success
            ? ActionConsts.Tournament.GetTournamentClaimHistorySuccess
            : ActionConsts.Tournament.GetTournamentClaimHistoryError,
      });
    },

  GetTournamentLeaderboard:
    (payload: ITournamentPage.Actions.IGetGETTournamentLeaderboardPayload) =>
    async (dispatch: Dispatch) => {
      const result = await TournamentHomeService.GetTournamentLeaderboard(
        payload
      );

      dispatch({
        payload: {
          tournamentLeaderboardData:
            result.response && result.success ? result.response : {},
        },
        type:
          result.response && result.success
            ? ActionConsts.Tournament.GetTournamentLeaderboardSuccess
            : ActionConsts.Tournament.GetTournamentLeaderboardFail,
      });
    },

  ClaimReward:
    (payload: ITournamentPage.Actions.IGetPOSTClaimTournamentRewardPayload) =>
    async (dispatch: Dispatch) => {
      const result = await TournamentHomeService.ClaimTournamentReward(payload);

      dispatch({
        payload: {
          claimedVoucher:
            result.response && result.success ? result.response : {},
        },
        type:
          result.response &&
          result.success &&
          ActionConsts.Tournament.ClaimRewardSuccess,
      });
    },

  JoinTournament:
    (payload: ITournamentPage.Actions.IGetGETTournamentLeaderboardPayload) =>
    async (dispatch: Dispatch, getState: any) => {
      const result = await TournamentHomeService.JoinTournament(payload);
      const { session } = getState().persistState;
      let updatedTournaments;
      let sessionTournaments = session?.tournaments ? session.tournaments : {};
      if (result.response && result.success) {
        updatedTournaments = Object.assign(sessionTournaments, result.response);
      } else {
        updatedTournaments = Object.assign(sessionTournaments, {});
      }
      dispatch({
        payload: { tournaments: updatedTournaments },
        type:
          result.response &&
          result.success &&
          ActionConsts.Authentication.SetUserTournament,
      });
    },

  SetSelectedTournament:
    (payload: TOURNAMENT_HOME) => async (dispatch: Dispatch) => {
      let user = store.getState().persistState.session;
      const leaderboard = payload && payload.leaderboard;
      // if(leaderboard && !leaderboard?.currentUser) {
      //      leaderboard.currentUser = {_id: user._id, profile_image: user.profileImage, name: user.name, rank: 0, wins: 0}
      // }

      dispatch({
        payload: {
          selectedTournament: payload,
          tournamentLeaderboardData: leaderboard,
        },
        type: ActionConsts.Tournament.SetSelectedTournament,
      });
    },

  TournamentExpire:
    (payload: ITournamentPage.Actions.IGetPOSTTournamentExpirePayload) =>
    async (dispatch: Dispatch) => {
      const result = await TournamentHomeService.TournamentExpire(payload);

      dispatch({
        payload: result.response && result.success ? result.response : {},
        type:
          result.response && result.success
            ? ActionConsts.Tournament.TournamentExpireSuccess
            : ActionConsts.Tournament.TournamentExpireFail,
      });
    },
  SetTournamentNotificationData:
    (payload: TOURNAMENT_NOTIFICATION_DATA) => async (dispatch: Dispatch) => {
      dispatch({
        payload: payload,
        type: ActionConsts.Tournament.SetTournamentNotificationData,
      });
    },
};
