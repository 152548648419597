import React, { useEffect, useState } from "react";
import { Player } from "@Components/MatchMaking/player";
import { USER_SESSION } from "@Services/API/Authentication/GETLoginResponse";
import styles from "./style.module.scss";
import { getProfileImage } from "@HelperFunctions";
import { useSelector } from "react-redux";
import { IStore } from "@Redux/IStore";
import { numOfPlayersInMatch } from "@Components/MatchMaking/index";
import { chunk } from "lodash";
import { PlayerStatusType } from "@Constants";
import { getTemplateForResult } from "./helpers";

export const PlayersView: React.FunctionComponent<{
  userProfileImage?: string[];
  gameResultScores: any;
  singlePlayer: boolean;
  revert?: boolean;
  backgroundColor?: string;
  showFriends: (index: number) => void;
  showGlow?: boolean;
  gameResult?: string;
  players: any;
  teamView: string;
  showingResult: boolean;
  apiPlayers?: any;
}> = ({
  gameResultScores,
  singlePlayer,
  backgroundColor,
  showFriends,
  showGlow,
  gameResult,
  players,
  teamView,
  showingResult,
  apiPlayers,
}) => {
  const [isTeamView, setIsTeamView] = useState(false);
  const { session: user } = useSelector((state: IStore) => state.persistState);
  const {
    specificSelectedConfiguration: matchMakingTemplate,
    isHost,
    matchObject: { allPlayers },
  } = useSelector((state: IStore) => state.multiPlayState);
  // console.log("result score needed",gameResultScores);
  const playersCount = numOfPlayersInMatch(matchMakingTemplate);
  let matchTemplate: any = matchMakingTemplate;
  if (showingResult) {
    if (matchTemplate?.isSelectable && apiPlayers?.length != playersCount) {
      matchTemplate = getTemplateForResult(apiPlayers?.length!);
      if (!matchTemplate) {
        matchTemplate = matchMakingTemplate;
      }
    }
  }
  const { playersInTeam, maxTeamCount, minTeamCount, maxPlayerCount, teamUp } =
    matchTemplate;
  const playersInMatch = numOfPlayersInMatch(matchTemplate);

  const checkView = () => {
    // console.log('teamView', teamUp)
    if (showingResult) {
      if (teamUp) setIsTeamView(true);
      else setIsTeamView(false);
    } else {
      if (
        (maxTeamCount > 0 && minTeamCount > 0) ||
        teamView === "team" ||
        (!isHost && teamUp)
      )
        return setIsTeamView(true);
      if (teamView === "individual" || (!isHost && !teamUp))
        return setIsTeamView(false);
      else return setIsTeamView(false);
    }
  };

  useEffect(() => {
    checkView();
  }, [teamUp, teamView]);

  const checkIsDisabled = (index: number) => {
    let disabled: boolean = false;
    if (allPlayers[index]?.status === PlayerStatusType.PENDING) {
      disabled = true;
    } else disabled = false;
    return disabled;
  };

  const getUserResultStatus = (player: any) => {
    let obj = { ...player };
    for (let i = 0; i < gameResultScores.win.length; i++) {
      if (player._id == gameResultScores.win[i].playerId) {
        obj.resultStatus = "win";
        obj.resultRank = gameResultScores.win[i].rank;
        return obj;
      }
    }
    for (let i = 0; i < gameResultScores.lose.length; i++) {
      if (player._id == gameResultScores.lose[i].playerId) {
        obj.resultStatus = "lose";
        obj.resultRank = gameResultScores.lose[i].rank;
        return obj;
      }
    }
    return obj;
  };
  const setUser = (index: number) => {
    let userPlayer;
    // if (index.ts === 0) userPlayer = user;
    if (!players.length && index === 0) userPlayer = user;
    else if (players.length) userPlayer = players[index];
    else userPlayer = null;
    if (userPlayer && gameResultScores) {
      userPlayer = getUserResultStatus(userPlayer);
    }
    return userPlayer;
  };
  // const array = [1, 2, 3, 4];
  const array = Array.apply(null, Array(playersInMatch)).map((val, idx) => idx);
  const newChunkedArray =
    maxTeamCount > 0 || maxPlayerCount > 2
      ? chunk(array, playersInTeam || 2)
      : chunk(array, maxPlayerCount);

  return (
    <>
      {newChunkedArray.map((chunkedArray: any, i: number) => {
        return (
          <div
            style={{
              height: "200px",
            }}
          >
            <div
              className={`d-flex flex-wrap justify-content-center ${
                isTeamView &&
                (i ? styles.team2Background : styles.team1Background)
              }`}
              style={{ height: "111px" }}
            >
              {chunkedArray.map((index: number) => {
                return (
                  <div
                    className={`${
                      chunkedArray?.length === 1 ? "w-50" : styles.playersView
                    }`}
                  >
                    <Player
                      user={setUser(index)}
                      gameResultScores={gameResultScores}
                      singlePlayer={singlePlayer}
                      index={index}
                      showGlow={showGlow}
                      gameResult={gameResult}
                      isTeamView={isTeamView}
                      revert={index % 2 !== 0}
                      showFriends={() => showFriends(index)}
                      timer={checkIsDisabled(index)}
                      chunkedArrayIndex={i}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
};
