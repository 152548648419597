import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export const LoadingGIF: React.FunctionComponent<{
  size?: any;
  color?: string;
  showLoading?: boolean;
  children?: any;
  tabHeight?: string;
}> = ({ size, color, showLoading, children, tabHeight }) => {
  if (!children) {
    return <img src="/loading_1.gif" style={{ width: "50px" }} />;
  } else {
    if (showLoading)
      return (
        <div
          className="w-100 d-flex align-items-center justify-content-center"
          style={{ height: tabHeight ? tabHeight : "100%" }}
        >
          <img
            src="/loading.gif"
            style={{ width: size === "2x" || size === "1x" ? "50px" : "120px" }}
          />
        </div>
      );
    else return children;
  }
};
