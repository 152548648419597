import { ActionConsts } from "@Definitions/ActionConsts";
import {
  addListnerOnRooms,
  addMessage,
  addOrRemoveUserInTypingList,
  getMessage,
} from "@Services/FirebaseEvents";
import { store } from "../../Redux/store";
import {
  MESSAGING_ROOM,
  POSTSendMessagePayload,
  ROOM_USER,
  USER_SESSION,
} from "@Interfaces";
import { MessagesActions } from "@Actions/Messages";
import { ConversationActions } from "@Actions/Conversation";
import { MessageStatus, MessageType } from "@Constants";

class ChatManager {
  // singleton instance
  //@ts-ignore
  static instance: ChatManager = null;

  // chat data room wise
  storedRooms = localStorage.getItem("rooms");
  rooms = this.storedRooms && JSON.parse(this.storedRooms);
  datasource: any = this.rooms?.length > 0 ? this.rooms : [];
  message: any = [];

  // room id of visible chat
  currentVisibleChatRoomId: string = "";

  // callback variable for updating messages on receiving
  refreshChatData: any = null;

  static createInstance() {
    let object = new ChatManager();
    object.readFromStorage();
    return object;
  }

  static getInstance() {
    if (!ChatManager.instance) {
      ChatManager.instance = ChatManager.createInstance();
    }
    return ChatManager.instance;
  }
  getCurrentVisibleRoom() {
    return this.currentVisibleChatRoomId;
  }

  public usersExceptMe = (users: ROOM_USER[]) => {
    const { persistState } = store.getState();
    const user = persistState.session;
    return users?.filter((u: ROOM_USER) => u._id !== user._id);
  };

  public async getRooms(): Promise<void> {
    const { persistState } = store.getState();
    const user = persistState.session;
    await store.dispatch(
      MessagesActions.GetFriends({ userId: user._id, type: 0 })
    );
  }

  public displayUsersName = (otherUsersInRoom: ROOM_USER[]) => {
    if (otherUsersInRoom.length === 1) return otherUsersInRoom[0].name;
    else {
      const lastName = otherUsersInRoom.slice(-1);
      return otherUsersInRoom.map((otherUser: ROOM_USER) => {
        const name = otherUser.name;
        if (otherUser._id === lastName[0]._id) {
          return name.split(" ")[0];
        }
        return name.split(" ")[0] + ", ";
      });
    }
  };

  public checkRoomExistenceInDataSourceById = async (id: string) => {
    let roomFound;
    roomFound = this.datasource.filter((e: MESSAGING_ROOM) => e._id === id);
    roomFound = roomFound.length > 0 ? roomFound[0] : null;
    if (!roomFound) {
      const result: any = await store.dispatch(
        MessagesActions.GetRoomById({ roomId: id })
      );
      let roomObj;
      if (result.success) {
        const room = result.response;
        roomObj = {
          _id: room._id,
          users: room.users,
          allMessagesdata: [],
          timeStamp: Date.now(),
          messageReceived: false,
          lastMessageId: -1,
          updatedAt: -1,
        };
        await this.addChatRoomIfNeeded(roomObj);
        await addListnerOnRooms([room]);
      }
    }
  };

  public addNewRoomInDataSource = async (room: MESSAGING_ROOM) => {
    let roomObj;
    roomObj = {
      _id: room._id,
      users: room.users,
      allMessagesdata: [],
      timeStamp: Date.now(),
      messageReceived: false,
      lastMessageId: -1,
      updatedAt: -1,
    };
    await this.addChatRoomIfNeeded(roomObj);
    await addListnerOnRooms([room]);
  };

  //     // check if room is already added in datasource or not
  checkIfRoomExistInDatasource(room: MESSAGING_ROOM) {
    const roomFound = this.datasource.filter(
      (e: MESSAGING_ROOM) => e._id === room._id
    );
    return roomFound.length > 0 ? roomFound[0] : null;
  }
  getRoomById(roomId: string) {
    const room = this.datasource.find((room: any) => room._id === roomId);
    return room;
  }

  // add room from game play if needed
  async addChatRoomIfNeeded(room: any) {
    const roomFound = this.checkIfRoomExistInDatasource(room);
    if (!roomFound) {
      // add room
      this.datasource.push(room);
      // this.roomDetailhash[room.peerId] = room._id;
      await this.updateStorage();
      // console.log("Room not found",);
    } else {
      // console.log("Room found in data source");
    }
  }

  //     // add rooms from api/rooms API is needed
  addRoomsIfNeededFromAPI(dataArray: any[]) {
    for (let room of dataArray) {
      // let newRoom = new ChatRoom(room._id, room.profile._id, room.profile);
      let roomObj = {
        _id: room._id,
        users: room.users,
        allMessagesdata: [],
        timeStamp: Date.now(),
        messageReceived: false,
        lastMessageId: -1,
        updatedAt: -1,
      };

      if (room.users.length > 1) this.addChatRoomIfNeeded(roomObj);
    }
  }

  //     // save chat data to local storage
  async updateStorage() {
    try {
      // if ((window as any).isLocalStorageEmpty) {
      //   return;
      // }
      // const datasourceString = this.datasource
      localStorage.setItem("rooms", JSON.stringify(this.datasource));
    } catch (e) {}
  }

  //     // read chat data from local storage
  async readFromStorage() {
    try {
      const storedData = localStorage.getItem("rooms");
      //@ts-ignore

      const persistState = JSON.parse(storedData);
      if (persistState !== null) {
        const dataArray = persistState;
        for (const savedRoom of dataArray) {
          let savedMsgs = savedRoom.allMessagesdata;
          let msgsArr = [];
          for (const savedMsg of savedMsgs) {
            let msg = {
              _id: savedMsg._id,
              type: savedMsg.type,
              message: savedMsg.message,
              sender: savedMsg.sender,
              // receiver: savedMsg.receiver,
              messageStatus: savedMsg.messageStatus,
              timeStamp: savedMsg.timeStamp,
              roomId: savedMsg.roomId,
              localImageUrl: savedMsg.localImageUrl,
              // users: savedMsg.users,
              isReadBy: savedMsg.isReadBy,
              isRead: savedMsg.isRead,
            };
            msgsArr.push(msg);
          }
          let lastMsgId = "";
          let msgsArrLength = msgsArr.length;
          if (msgsArrLength > 0) {
            lastMsgId = msgsArr[msgsArrLength - 1]._id;
          }
          let room = {
            _id: savedRoom._id,
            users: savedRoom.users,
            allMessagesdata: msgsArr,
            timeStamp: savedRoom.timeStamp,
            lastMessage: savedRoom.lastMessage,
            messageReceived: this.checkIsMessageReadOrNot(
              savedRoom,
              savedRoom.lastMessage
            ),
            lastMessageId: lastMsgId,
          };

          this.datasource.push(room);
          // this.roomDetailhash[room.peerId] = room._id;
        }
        this.showBadgeOnAnyUnreadMessage();
      }
    } catch (e) {
      // console.log("ChatManager.js ", "Failed to fetch the data from storage");
    }
  }

  // get index.ts of chat room from datasource
  getRoomIndex(roomID: string) {
    //@ts-ignore
    return this.datasource.findIndex((room) => room._id === roomID);
  }

  // add message in room
  addMessageInRoom(chatMsg: any, roomIndex: number) {
    // console.log("add message in room", chatMsg)
    this.datasource[roomIndex].allMessagesdata.push(chatMsg);
    this.datasource[roomIndex].timeStamp = Date.now();

    if (chatMsg?.type === MessageType.INVITE) {
      this.updateStorage();
      this.refreshDataInChatWindow();
    }
  }

  async updateChatRoomsList(lastMessage: any) {
    store.dispatch(
      ConversationActions.UpdateChatRoomList({
        updatedAt: Date.now(),
        lastMessage: lastMessage,
      })
    );
  }
  getMessageIndex(roomIndex: any, msgId: any, _id: number | string) {
    return this.datasource[roomIndex]?.allMessagesdata?.findIndex(
      (message: any) => {
        return message._id == msgId || message._id === _id;
      }
    );
  }

  public checkIsMessageReadOrNot = (room: MESSAGING_ROOM, lastMessage: any) => {
    let { persistState } = store.getState();
    let user = persistState.session;
    if (lastMessage?.sender === user._id) return false;
    const lastMsgReadByUsers = lastMessage?.isReadBy;
    if (lastMessage?.sender !== user._id) {
      if (lastMsgReadByUsers?.includes(user._id)) return false;
      else return true;
    }
  };
  updateMessageIfNeeded(
    roomIndex: number,
    raw: any,
    updatedAlreadyReadMessages: boolean,
    isPreviousMsg = false
  ) {
    let msgObj = raw;
    var msgIndex = 0;
    msgIndex = this.getMessageIndex(
      roomIndex,
      updatedAlreadyReadMessages ? msgObj._id : msgObj.previousId,
      msgObj._id
    );
    let chatMsg = {
      _id: msgObj._id,
      type: msgObj.type,
      message: msgObj.message,
      sender: msgObj.sender,
      // receiver: msgObj.receiver,
      messageStatus: msgObj.messageStatus,
      timeStamp: msgObj.timeStamp,
      roomId: msgObj.roomId,
      localImageUrl: msgObj.localImageUrl,
      // users: msgObj.users,
      isReadBy: msgObj.isReadBy,
      previousId: msgObj.previousId,
      isRead: msgObj.isRead,
    };

    // console.log('msgIndex',this.datasource[roomIndex].lastMessage)
    if (
      msgIndex < 0 &&
      this.datasource[roomIndex].lastMessage?._id !== chatMsg._id
    ) {
      this.datasource[roomIndex].timeStamp = Date.now();
      // this.datasource[roomIndex].messageReceived = this.datasource[roomIndex].lastMessageId !== chatMsg._id ;
      this.datasource[roomIndex].messageReceived = this.checkIsMessageReadOrNot(
        this.datasource[roomIndex],
        chatMsg
      );

      if (isPreviousMsg) {
        this.datasource[roomIndex].allMessagesdata.unshift(chatMsg);
      } else {
        this.datasource[roomIndex].allMessagesdata.push(chatMsg);
        this.datasource[roomIndex].lastMessageId = chatMsg._id;
        this.datasource[roomIndex].lastMessage = chatMsg;
      }

      return true;
    } else {
      if (this.datasource[roomIndex]) {
        this.datasource[roomIndex].allMessagesdata[msgIndex] = chatMsg;
        // this.datasource[roomIndex].messageReceived = this.datasource[roomIndex].lastMessageId !== chatMsg._id;
        this.datasource[roomIndex].lastMessageId = chatMsg._id;
        this.datasource[roomIndex].lastMessage = chatMsg;
      }
      return true;
    }
  }

  public showBadgeOnAnyUnreadMessage = () => {
    const rooms = this.datasource;
    if (rooms.length > 0) {
      return rooms.some(
        (room: MESSAGING_ROOM) => room.messageReceived === true
      );
    }
  };

  public getTypingArrayOfCurrentRoom = (roomId: string) => {
    let { conversation } = store.getState();
    let typingUserList = conversation.typingUserList;
    if (typingUserList) {
      return typingUserList[roomId];
    }
  };

  readMessageOfCurrentRoom(roomId: string) {
    let roomIndex = this.getRoomIndex(roomId);
    if (roomIndex > -1) this.datasource[roomIndex].messageReceived = false;
  }

  // update message in room
  async updateMessageInRoom(
    roomId: string,
    raw: any,
    updatedAlreadyReadMessages: boolean,
    isPrevious: boolean
  ) {
    if (roomId) {
      let roomIndex = this.getRoomIndex(roomId);
      let updated = this.updateMessageIfNeeded(
        roomIndex,
        raw,
        updatedAlreadyReadMessages,
        isPrevious
      );
      if (updated) {
        this.updateStorage();
        this.refreshDataInChatWindow();
      }
    }
  }

  async sendMessage(chatMsg: any, resendMsg?: boolean) {
    if (chatMsg.messageStatus) delete chatMsg.messageStatus;
    const { message, sender, roomId, type, _id } = chatMsg;
    let roomIndex = this.getRoomIndex(chatMsg.roomId);
    if (roomIndex !== -1) {
      if (!resendMsg) await this.addMessageInRoom(chatMsg, roomIndex);
      await this.refreshDataInChatWindow();

      // this.updateStorage();
      const messageObj: POSTSendMessagePayload = {
        body: message,
        senderId: sender,
        roomId: roomId,
        type: type,
        previousId: _id,
      };
      // this.store.dispatch(sendMessageToRoom(message,sender,roomId,type))
      const result: any = await store.dispatch(
        ConversationActions.SendMessageOnServer(messageObj)
      );
      if (!result.success) {
        const msgsArrayLength =
          this.datasource[roomIndex].allMessagesdata.length;
        this.datasource[roomIndex].allMessagesdata[
          msgsArrayLength - 1
        ].messageStatus = MessageStatus.PENDING;
        await this.refreshDataInChatWindow();
      }
    }
  }

  updateTypingIndicators(roomId: string, typingUsers: any) {
    if (typingUsers) {
      let obj: any = {};
      obj[roomId] = typingUsers;
      store.dispatch({
        type: ActionConsts.Conversation.UpdateTypingUserList,
        payload: obj,
      });
    } else {
      let obj: any = {};
      obj[roomId] = null;
      store.dispatch({
        type: ActionConsts.Conversation.UpdateTypingUserList,
        payload: obj,
      });
    }
  }
  sendTypingIndicator(roomId: string, isTyping: boolean) {
    let { persistState } = store.getState();
    let user = persistState.session;
    //Api Call Some oNe is Typing

    this.sendTypingIdToFirebase(user._id, isTyping, roomId);
  }

  public sendIsMessageRead = async (roomId: string) => {
    let { persistState } = store.getState();
    let user = persistState.session;
    const lastMessageId = await this.getLastMsgId(roomId);
    if (lastMessageId > -1)
      store.dispatch(
        ConversationActions.MarkMessageAsRead({
          roomId,
          messageId: lastMessageId,
          userId: user._id,
        })
      );
  };

  //@ts-ignore
  async getLastMsgId(roomId: string) {
    let roomIndex = await this.getRoomIndex(roomId);
    if (roomIndex > -1) {
      return await this.datasource[roomIndex].lastMessageId;
    }
  }
  async getUpdatedAt(roomIndex: number) {
    if (roomIndex > -1) {
      return this.datasource[roomIndex]?.updatedAt;
    }
  }

  async getfirstMsgId(roomId: string) {
    let roomIndex = this.getRoomIndex(roomId);

    if (roomIndex > -1) {
      return this.datasource[roomIndex].allMessagesdata[0]?._id;
    }
  }

  async getRoomWithOtherUser(otherUser: USER_SESSION) {
    const rooms = this.datasource;
    const room = rooms.filter(
      (room: MESSAGING_ROOM) =>
        room.users.length === 2 &&
        room.users.find((user) => user._id === otherUser._id)
    );
    return room[0];
  }

  async getAndUpdatePreviousMessageOfRoomIfAny(roomId: string) {
    const lastMessageId = await this.getfirstMsgId(roomId);
    let response: any = "";
    let roomIndex = this.getRoomIndex(roomId);
    let allUsersInRoom: any;
    if (roomIndex > -1) allUsersInRoom = this.datasource[roomIndex].users;
    let fetchLatest = false;
    response = await store.dispatch(
      ConversationActions.ReceiveMessageFromServer({
        roomId,
        lastMessageId,
        fetchLatest,
      })
    );
    const messagesArray = response.data;
    let msgArray: any = [];
    let messageObj;
    if (response.success && messagesArray?.length) {
      //@ts-ignore
      global.previousMsg = true;
      messagesArray.map((msg: any) => {
        messageObj = {
          _id: msg.id,
          type: msg.type,
          message: msg.body,
          sender: msg.senderId,
          timeStamp: new Date(msg.createdAt).getTime(),
          roomId: msg.roomId,
          previousId: msg.previousId,
          users: msg.users,
          isReadBy: msg.isReadBy,
          isRead: this.checkIsMessageRead(
            msg.isReadBy,
            allUsersInRoom,
            msg.senderId
          ),
        };
        msgArray.push(messageObj);
      });
    }

    // this.message = msgArray;
    msgArray.map(async (msg: any) => {
      await this.updateMessageInRoom(roomId, msg, false, true);
    });
  }

  checkIsMessageRead(
    readArray: string[],
    allUsersInRoom: ROOM_USER[],
    senderId: string
  ) {
    let {
      persistState: { session },
    } = store.getState();
    if (senderId === session._id) {
      const msgReadArrayExceptMe = readArray?.filter(
        (userId: string) => userId !== session._id
      );
      const otherUsers = this.usersExceptMe(allUsersInRoom);
      const isMessageReadFromArray = otherUsers.every((user: ROOM_USER) =>
        msgReadArrayExceptMe?.includes(user._id)
      );
      return isMessageReadFromArray;
    } else return false;
  }
  async updateDataOnFirebaseTrigger(roomId: any, newData: any) {
    let response: any = "";
    let fetchOnUpdatedAt = false;

    let roomIndex = this.getRoomIndex(roomId);
    let allUsersInRoom: any;
    if (roomIndex > -1) allUsersInRoom = this.datasource[roomIndex].users;
    if (newData && roomIndex > -1) {
      // response=await store.dispatch(
      // ConversationActions.ReceiveAllMessageFromServer(roomId ))
      const lastUpdatedAt = await this.getUpdatedAt(roomIndex);
      const lastMessageId = await this.getLastMsgId(roomId);
      if (
        newData.lastMsgId === lastMessageId &&
        newData?.updatedAt !== lastUpdatedAt
      )
        fetchOnUpdatedAt = true;

      this.datasource[roomIndex].updatedAt = newData?.updatedAt;

      if (lastMessageId && lastMessageId > -1) {
        let fetchLatest = true;
        if (fetchOnUpdatedAt) {
          response = await store.dispatch(
            ConversationActions.ReceiveMessageFromServer({
              roomId,
              fetchLatest: fetchOnUpdatedAt,
            })
          );
          // if (response.success)
        } else if (lastMessageId !== newData.lastMsgId) {
          response = await store.dispatch(
            ConversationActions.ReceiveMessageFromServer({
              roomId,
              lastMessageId,
              fetchLatest,
            })
          );
        }
      } else {
        let fetchLatest = true;
        response = await store.dispatch(
          ConversationActions.ReceiveMessageFromServer({ roomId, fetchLatest })
        );
      }

      this.updateTypingIndicators(roomId, newData?.isTyping);
    }

    const messagesArray = response?.data;
    let msgArray: any = [];
    let messageObj;
    if (response.success && messagesArray?.length) {
      //@ts-ignore
      global.previousMsg = false;
      messagesArray.map((msg: any) => {
        messageObj = {
          _id: msg.id,
          type: msg.type,
          message: msg.body,
          sender: msg.senderId,
          timeStamp: new Date(msg.createdAt).getTime(),
          roomId: msg.roomId,
          previousId: msg.previousId,
          // users: msg.users,
          isReadBy: msg.isReadBy,
          isRead: this.checkIsMessageRead(
            msg.isReadBy,
            allUsersInRoom,
            msg.senderId
          ),
        };
        msgArray.unshift(messageObj);
      });
    }

    this.message = msgArray;
    this.message.map((msg: any) => {
      this.updateMessageInRoom(roomId, msg, fetchOnUpdatedAt, false);
    });
    if (msgArray.length > 0) {
      await this.updateChatRoomsList(msgArray[msgArray.length - 1]);
    }

    //  Firebase flow with getting all Messages

    // let messages1 =await firebaseManager.getMessage(roomId,newData.lastMsgID);
    // let messages = await getMessage(roomId);
    // if(messages){
    //   messages = Object.values(messages);
    //   this.message = messages;
    //   for (let i = 0; i < this.message.length; i++) {
    //     this.updateMessageInRoom(roomId, this.message[i]);
    //   }
    // }
  }
  sendTypingIdToFirebase(userId: string, isTyping: boolean, roomId: string) {
    addOrRemoveUserInTypingList(userId, roomId, isTyping);
  }

  refreshDataInChatWindow() {
    if (this.refreshChatData) {
      // console.log("*this", this.refreshChatData);
      //@ts-ignore
      this.refreshChatData();
    }
  }

  getMessagesOfCurrentRoom(id: string) {
    // console.log("Getting msgs of room", roomId);
    // if (this.currentVisibleChatRoomId) {
    let roomId = id;
    if (!roomId) {
      roomId = this.currentVisibleChatRoomId;
    }
    let roomIndex = this.getRoomIndex(roomId);
    if (roomIndex >= 0) {
      // console.log("roomIndex", roomIndex);
      const msgData = this.datasource[roomIndex].allMessagesdata;
      // this.updateMessagesWithDateTag(msgData);

      if (msgData != null) {
        // console.log("data found", msgData);
        return msgData;
      }
    }
    return [];
    // }
  }

  getAllChatRooms() {
    this.datasource.sort(function (x: MESSAGING_ROOM, y: MESSAGING_ROOM) {
      const date1 = new Date(x.lastMessage?.timeStamp);
      const date2 = new Date(y.lastMessage?.timeStamp);
      return date2.getTime() - date1.getTime();
    });

    let filteredArray = [];

    filteredArray = this.datasource;

    return filteredArray;
  }
  getFriendsChatRooms() {
    this.datasource.sort(function (x: MESSAGING_ROOM, y: MESSAGING_ROOM) {
      return y.timeStamp! - x.timeStamp!;
    });
    let { persistState } = store.getState();
    let user = persistState.session;

    let friendsArray = user.friends != null ? user.friends : [];
    let filteredArray = [];
    //@ts-ignore
    filteredArray = this.datasource.filter((chat: MESSAGING_ROOM) => {
      const otherUsers = this.usersExceptMe(chat.users);
      if (otherUsers.length === 1) {
        if (friendsArray.includes(otherUsers[0]._id)) {
          chat.isFriend = true;
          return true;
        } else {
          chat.isFriend = false;
          return false;
        }
      }
    });
    if (user.isBlockedBy != null && user.isBlockedBy.length > 0)
      filteredArray =
        filteredArray.length &&
        filteredArray.filter((singleRoom: MESSAGING_ROOM) => {
          const otherUsers = this.usersExceptMe(singleRoom.users);
          if (otherUsers.length === 1)
            return !user.isBlockedBy.includes(otherUsers[0]._id);
        });

    if (user.hasBlocked != null && user.hasBlocked.length > 0)
      filteredArray =
        filteredArray.length &&
        filteredArray.filter((singleRoom: MESSAGING_ROOM) => {
          const otherUsers = this.usersExceptMe(singleRoom.users);
          if (otherUsers.length === 1)
            return !user.hasBlocked.includes(otherUsers[0]._id);
        });

    return filteredArray;
  }

  clearChatRooms() {
    this.datasource = [];
  }
}

const chatManager = ChatManager.getInstance();
export default chatManager;
