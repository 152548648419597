import { IGameItem } from "./IGameItem";
import { useSelector } from "react-redux";
import { LazyImage } from "@Styled/LazyImage";
import React, { useEffect, useState } from "react";

export const GameItemWithoutBackground: React.FunctionComponent<
  IGameItem.IProps
> = ({
  game,
  noOfItemsPerRow,
  onGameClick,
  selectedLanguage,
  gameCategoryDirectionVertical,
  itemSpace,
  index,
}) => {
  const uiTheme = useSelector((state: any) => state.themeReducer.theme);

  const getRandomValue = (min: any, max: any) => {
    min = Math.max(0, min);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const [minValue, setMinValue] = useState(550);
  const [maxValue, setMaxValue] = useState(1800);

  const [randomValue, setRandomValue] = useState(
    getRandomValue(minValue, maxValue)
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setMinValue((prev) => prev + 10);
      setMaxValue((prev) => Math.max(prev - 50, minValue));
      setRandomValue(getRandomValue(minValue, maxValue));
    }, 12000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div
      onClick={() => {
        onGameClick(game);
      }}
      className=""
      style={{
        marginRight: selectedLanguage === "en" ? 12 : 0,
        marginLeft: selectedLanguage === "en" ? 0 : 12,
      }}
    >
      <LazyImage
        src={
          game.gifUrl && game.gifUrl !== "false" ? game.gifUrl : game.thumbnail
        }
        alt={game.title}
        height="120px"
        width="120px"
        borderRadius="15px"
      />
      <div className="mt-2">
        <div
          className=""
          style={{
            fontSize: "14px",
            color: uiTheme === "dark" ? "#FFFFFF" : "#1D1540",
            fontWeight: "bold",
          }}
        >
          {game.title}
        </div>
        <div
          className={`
          d-flex
          flex-row 
          align-items-center
          mt-2
          ${selectedLanguage === "en" ? "" : "flex-row-reverse"}
          `}
        >
          <img
            src={
              uiTheme === "dark"
                ? "/images/ppViews.svg"
                : "/images/ppViewsDark.svg"
            }
            width="14px"
            height="14px"
          />
          <div
            className="ml-2"
            style={{
              fontSize: "12px",
              color: uiTheme === "dark" ? "#FFFFFF" : "#1D1540",
            }}
          >
            {randomValue}
          </div>
        </div>
      </div>
    </div>
  );
};
