import React, { useEffect } from "react";
import { Redirect, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { USER_SESSION } from "@Interfaces";
import { AuthenticationActions } from "@Actions/Authentication";

export const Authenticated: React.FunctionComponent<{
  session: USER_SESSION;
  userGeoLocation: any;
  onScroll?: () => void;
  noPadding?: boolean;
  mobileDeviceUserId?: string | null;
}> = ({
  children,
  session,
  onScroll,
  userGeoLocation,
  mobileDeviceUserId,
  noPadding,
}) => {
  const theme = useSelector((state: any) => state.themeReducer.theme);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const queryString = query.get("isMobile") as string;

  const isMessagesWindow = window.location.href.indexOf("/messages") > 0;
  const backgroundImage = isMessagesWindow
    ? "bg-message-image"
    : "bg-main-image";

  useEffect(() => {
    if (!queryString ? !session || !("_id" in session) : !mobileDeviceUserId) {
      history.push("/login");
    }
  }, [session]);

  return (
    <div
      className={`hide-y-scroller ${noPadding ? "px-0" : "px-0"} w-100`}
      style={{
        overflowY: "scroll",
        overflowX: "hidden",
        height: "100%",
        backgroundColor: theme === "dark" ? "#161032" : "#fff",
      }}
      onScroll={(e: any) => {
        onScroll && onScroll();
      }}
    >
      {children}
    </div>
  );
};
