// #region Global Imports
import { Dispatch } from "redux";
// #endregion Global Imports

// #region Local Imports
import { ActionConsts } from "@Definitions";
import { DiscoverPeopleService } from "@Services/API/DiscoverPeople";
// #endregion Local Imports

// #region Interface Imports
import { IDiscoverPeoplePage } from "@Interfaces";
// #endregion Interface Imports

export const DiscoverPeopleActions = {
  DiscoverPeople:
    (payload: IDiscoverPeoplePage.Actions.IGetGETDiscoverPeoplePayload) =>
    async (dispatch: Dispatch) => {
      const result: any = await DiscoverPeopleService.DiscoverPeople(payload);
      return result;
    },
  RecomendedPeople:
    (payload: IDiscoverPeoplePage.Actions.IGetGETDiscoverPeoplePayload) =>
    async (dispatch: Dispatch) => {
      const result: any = await DiscoverPeopleService.DiscoverPeople(payload);
      console.log("users ress", result);

      return result;
    },
  GetUserFriends:
    (payload: IDiscoverPeoplePage.Actions.IGetGETFriendsPayload) =>
    async (dispatch: Dispatch) => {
      const result = await DiscoverPeopleService.GetUserFriends(payload);
      dispatch({
        payload: result.success ? result.response : [],
        type: ActionConsts.Authentication.SetUserFriendsData,
      });
      return result;
    },
};
