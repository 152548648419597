// #region Global Imports
import "isomorphic-unfetch";
import { encode } from "base-64";
// import getConfig from "next/config";
import { stringify } from "query-string";
// #endregion Global Imports

// #region Interface Imports
import { HttpModel } from "@Interfaces";
import { getCookie } from "@Services/Cookie";
import { EventEmitterSingleton } from "@Services/UI";
import { decryptData, encryptData } from "@HelperFunctions";
// #endregion Interface Imports

// const {
//     publicRuntimeConfig: { API_KEY, API_URL },
// } = getConfig();
const BaseUrl = `${process.env.REACT_APP_API_URL}`;

const getUpdatedParams = (url: string, params?: HttpModel.IRequestQueryPayload) : HttpModel.IRequestQueryPayload | undefined => {
  const isWeb = "isWeb";
  const isFbRequest = url.includes("https://graph");
  if(isFbRequest) return undefined
  if (!params) {
    params = {}
    params[isWeb] = true;
  } else if( !params.hasOwnProperty(isWeb)) {
    params[isWeb] = true;
  }
  return params
}

export const Http = {
  RequestWithToken: async <A>(
    methodType: string,
    url: string,
    params?: HttpModel.IRequestQueryPayload,
    payload?: HttpModel.IRequestPayload
  ): Promise<A> => {
    return new Promise((resolve, reject) => {
      params = getUpdatedParams(url, params);
      const query = params ? `?${stringify({ ...params })}` : "";

      fetch(`${url}${query}`, {
        body: JSON.stringify(payload),
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + encode("gago:VTfmPVKm*M3e"),
        },
        method: `${methodType}`,
      })
        .then(async (response) => {
          if (response.status === 200) {
            return response.json().then(resolve);
          }
          if (response.status === 400) {
            return response.json().then(resolve);
          }
          return reject(response);
        })
        .catch((e) => {
          if (!navigator.onLine) {
            EventEmitterSingleton().emit("NO_NETWORK");
            reject(e);
          } else {
            reject(e);
          }
        });
    });
  },
  Request: async <A>(
    methodType: string,
    url: string,
    params?: HttpModel.IRequestQueryPayload,
    payload?: HttpModel.IRequestPayload
  ): Promise<A> => {
    return new Promise((resolve, reject) => {
      params = getUpdatedParams(url, params);
      const query = params ? `?${stringify({ ...params })}` : "";

      fetch(`${url}${query}`, {
        body: JSON.stringify(payload),
        cache: "no-cache",
        mode: "cors",
        headers: {
          "content-type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "x-access-token":
            "Bearer " + decryptData(getCookie("api-token") || ""),
        },
        method: `${methodType}`,
      })
        .then(async (response) => {
          if (response.status === 200 || response.status === 201) {
            return response.json().then(resolve);
          }
          return reject(response);
        })
        .catch((e) => {
          if (!navigator.onLine) {
            EventEmitterSingleton().emit("NO_NETWORK");
            reject(e);
          } else {
            reject(e);
          }
        });
    });
  },
  RequestWithoutToken: async <A>(
    methodType: string,
    url: string,
    params?: HttpModel.IRequestQueryPayload,
    payload?: HttpModel.IRequestPayload
  ): Promise<A> => {
    return new Promise((resolve, reject) => {
      params = getUpdatedParams(url, params);
      const query = params ? `?${stringify({ ...params })}` : "";

      fetch(`${url}${query}`, {
        body: JSON.stringify(payload),
        cache: "no-cache",
        // mode: "cors",
        // headers: {
        //   "content-type": "application/json",
        //   "Access-Control-Allow-Origin": "*",
        //   "x-access-token":
        //     "Bearer " + decryptData(getCookie("api-token") || ""),
        // },
        method: `${methodType}`,
      })
        .then(async (response) => {
          if (response.status === 200 || response.status === 201) {
            return response.json().then(resolve);
          }
          return reject(response);
        })
        .catch((e) => {
          if (!navigator.onLine) {
            EventEmitterSingleton().emit("NO_NETWORK");
            reject(e);
          } else {
            reject(e);
          }
        });
    });
  },
  UploadFile: async <A>(
    methodType: string,
    url: string,
    params?: HttpModel.IRequestQueryPayload,
    payload?: HttpModel.IRequestPayload
  ): Promise<A> => {
    return new Promise((resolve, reject) => {
      params = getUpdatedParams(url, params);
      const query = params ? `?${stringify({ ...params })}` : "";

      fetch(`${url}${query}`, {
        body: payload,
        headers: {
          "x-access-token":
            "Bearer " + decryptData(getCookie("api-token") || ""),
        },
        method: `${methodType}`,
      } as any)
        .then(async (response) => {
          if (response.status === 200) {
            return response.json().then(resolve);
          }
          return reject(response);
        })
        .catch((e) => {
          if (!navigator.onLine) {
            EventEmitterSingleton().emit("NO_NETWORK");
            reject(e);
          } else {
            reject(e);
          }
        });
    });
  },
  GetHTMLText: async <A>(
    methodType: string,
    url: string,
    params?: HttpModel.IRequestQueryPayload,
    payload?: HttpModel.IRequestPayload
  ): Promise<A> => {
    return new Promise((resolve, reject) => {
      params = getUpdatedParams(url, params);
      const query = params ? `?${stringify({ ...params })}` : "";

      fetch(`${url}${query}`, {
        headers: {
          Accept:
            "text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9",
        },
      })
        .then(function (response) {
          if (response.status === 200) {
            // When the page is loaded convert it to text
            return response.text().then(resolve as any);
          }
          reject(response);
        })
        .catch((e) => {
          if (!navigator.onLine) {
            EventEmitterSingleton().emit("NO_NETWORK");
            reject(e);
          } else {
            reject(e);
          }
        });
    });
  },
};
