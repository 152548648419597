// Global Imports
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import flatten from "lodash/flatten";
//
// Local Imports
import { HomeActions } from "@Actions/Home";
import { ExclusiveFeaturesActions } from "@Actions/ExclusiveFeatures";
import { SinglePlayerGameBoard } from "@Components/SinglePlayerGameBoard";
import { LoadingGIF } from "@Components/LoadingSpinner/LoadingGIF";

import styles from "./style.module.scss";
import { IStore } from "@Redux/IStore";
import { GAME_CATEGORY, GAME_ITEM } from "@Interfaces";
import {
  AnalyticsEventsName,
  SendAnalyticsToFirebase,
} from "@Services/FirebaseEvents";
import {
  getBC1Color,
  getConfigurations,
  isPaidContentAllowed,
} from "@HelperFunctions";
import { AccessResponseStatus } from "@Constants";
import { GameItemWithoutBackground } from "@Components/GameItem/GameItemWithoutBackground";
import { GameTypeView } from "@Components/GameTypeView";
import { MultiGamePlayAction } from "@Actions/MultiGamePlayAction";
import { getCookie } from "@Services/Cookie";
import HomeBanner from "@Components/HomeBanner";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import { FreeMode } from "swiper";
import { GameItemGrids } from "@Components/GameItem/GameItemGrids";

let loadingOnScroll = false;
export const HomeContainerNewDesign: React.FunctionComponent<{
  onRequestMatchMaking: (a: number, b: boolean) => void;
  setShowLowBalanceModal: (a: boolean) => void;
  setShowNoNameModal: (a: boolean) => void;
  setShowTermsConditions: (a: boolean) => void;
  showSubscriptionSuccessfull: boolean;
  setShowSubscriptionSuccessfull: (a: boolean) => void;
  setShowPaywall: (a: boolean) => void;
  setPremiumFeature: (a: boolean) => void;
  isFirebaseInitilized: boolean;
}> = ({
  onRequestMatchMaking,
  setShowLowBalanceModal,
  setShowNoNameModal,
  showSubscriptionSuccessfull,
  setShowSubscriptionSuccessfull,
  setShowPaywall,
  setShowTermsConditions,
  setPremiumFeature,
  isFirebaseInitilized,
}) => {
  const theme = useSelector((state: any) => state.themeReducer.theme);
  const [showGameBoard, setShowGameBoard] = useState(false);
  const dispatch = useDispatch();
  const persistState = useSelector((state: IStore) => state.persistState);
  const { selectedGameConfig } = useSelector(
    (state: IStore) => state.multiPlayState
  );
  const homeState = useSelector((state: IStore) => state.home);
  const [itemSpace, setItemSpace] = useState(8);
  const { userHasLowBalance, gamesListLoaded, homePageNo } = homeState;
  let {
    gamesList,
    recentlyPlayedGameIds,
    selectedLanguage,
    session,
    userHasNoName,
    persistFirebaseConfiguration: firebaseConfig = { regions: [] },
    showPaywall,
    gameConfigJson,
    recentPlayed,
  } = persistState;
  const [loadingGames, setLoadingGames] = useState(false);
  const [showGameConfigurationScreen, setShowGameConfigurationScreen] =
    useState(false);
  const [gameCategoryList, setGameCategoryList] = useState<GAME_CATEGORY[]>([]);
  const [recentlyPlayedGames, setRecentlyPlayedGames] = useState<GAME_ITEM[]>(
    []
  );
  const history = useHistory();
  const [selectedGame, setSelectedGame] = useState<GAME_ITEM | any>({});
  const { t } = useTranslation();
  const state = useSelector((state) => state);

  if ((gamesList == null || gamesList.length == 0) && !loadingGames) {
    setLoadingGames(true);
  }
  useEffect(() => {
    setRecentlyPlayedGames(recentPlayed);
  }, [recentPlayed, recentlyPlayedGameIds]);

  useEffect(() => {
    let scrollElement = document.getElementById("mainScroll");
    if (scrollElement) {
      if (!scrollElement.onscroll) {
        scrollElement.addEventListener(
          "scroll",
          async function (event: any) {
            if (isInView("sfakndfaja5sad5155")) {
              if (!loadingOnScroll) {
                loadingOnScroll = true;
                await dispatch(
                  HomeActions.GetAppDesignGamesList(
                    getGameListparams(),
                    recentlyPlayedGameIds
                  )
                );
                loadingOnScroll = false;
              }
            }
          },
          false
        );
      }
    }
    return () => {
      scrollElement?.removeEventListener("scroll", () => {});
    };
  }, []);
  const getPageNo = () => {
    let page = localStorage.getItem("ipn");
    if (page) return +page;
    return 1;
  };
  const getGameListparams = () => {
    var configNo = localStorage.getItem("configNo");
    if (!configNo) {
      configNo = "0";
    }
    return {
      pageNo: getPageNo() + 1,
      itemCount: 12,
      configVersion: parseFloat(configNo),
    };
  };
  const isInView = (id: string) => {
    let currentUser = document.getElementById(id);
    if (currentUser) {
      const rect = currentUser.getBoundingClientRect();

      let isInViewPort =
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth);
      return isInViewPort;
    }
  };
  useEffect(() => {
    if (!gamesListLoaded) {
      (async () => {
        if (!gamesList.length) setLoadingGames(true);
        getCookie("api-token") &&
          (await dispatch(
            HomeActions.GetAppDesignGamesList(
              {
                pageNo: homePageNo,
                itemCount: 12,
                configVersion: persistState?.persistFirebaseConfiguration?.v,
              },
              recentlyPlayedGameIds
            )
          ));
        setLoadingGames(false);
      })();
    }
  }, [getCookie("api-token")]);

  useEffect(() => {
    if (gamesList && recentlyPlayedGameIds) {
      const allGames = flatten(
        gamesList.map((category: GAME_CATEGORY) => {
          return [...category.games];
        })
      );
      const _recentlyPlayedGames: any = [];
      recentlyPlayedGameIds.forEach((playedGameId) => {
        const playedGame = allGames.filter((game: any) => {
          return game.gameId === playedGameId;
        });
        playedGame.length && _recentlyPlayedGames.push(playedGame[0]);
      });
      let allCat = gamesList.filter((category: GAME_CATEGORY) => {
        return category.viewType !== "recentPlayed";
      });
      setGameCategoryList(allCat);
    }
  }, [recentlyPlayedGameIds, gamesList]);

  useEffect(() => {
    if (!session._id && (window as any).userSessionTokenChanged)
      history.push("/login");
  }, [session]);

  const cancelMatchMaking = () => {
    setSelectedGame({});
    setShowGameBoard(false);
  };
  const onPremiumGameClick = () => {
    //setShowPaywall(true);
    dispatch(ExclusiveFeaturesActions.SetExclusiveFeature(true));
  };

  const playGame = (game: GAME_ITEM) => {
    if (!persistState.session._id) {
      history.push("/login");
      return;
    }

    if (userHasNoName) {
      setShowNoNameModal(true);
      return;
    }

    const { accessBlockReason } = isPaidContentAllowed(session);

    accessBlockReason === AccessResponseStatus.LOW_BALANCE
      ? setShowLowBalanceModal(true)
      : onPremiumGameClick();

    if (game.multiplayer) {
      getConfigurations(game, gameConfigJson, dispatch);
      if (game.configurations?.length > 1) {
        dispatch(MultiGamePlayAction.SetShowGameTypeView(true));
        setShowGameConfigurationScreen(true);
      } else {
        onRequestMatchMaking(game.gameId, true);
      }

      // Analytics
      SendAnalyticsToFirebase(AnalyticsEventsName.ON_MP_GAME_ITEM_PRESSED);
    } else {
      setSelectedGame(game);
      setShowGameBoard(true);

      // Analytics
      SendAnalyticsToFirebase(AnalyticsEventsName.ON_SP_GAME_ITEM_PRESSED);
    }
  };

  const checkGamesLength = (
    noOfItemsPerRow: number | undefined,
    noOfRows: number,
    gamesArray: GAME_ITEM[],
    i: number
  ) => {
    let totalNoRows;
    let btn = false;
    let games = gamesArray;
    if (noOfItemsPerRow && noOfRows) {
      totalNoRows = noOfItemsPerRow * noOfRows;
      if (gamesArray.length > totalNoRows) {
        const arrayslice = gamesArray.slice(0, totalNoRows - 1);
        games = arrayslice;
        btn = true;
        return { games, btn, totalNoRows };
      } else {
        return { games };
      }
    } else return { games };
  };

  // console.log(showGameConfigurationScreen);

  return (
    <div
      className={"d-flex flex-column pb-3 " + (loadingGames ? "h-100" : "")}
      id="scroll"
      style={{
        backgroundColor: theme !== "dark" ? "#FFFFFF" : "#161032",
      }}
    >
      {showGameConfigurationScreen && (
        <GameTypeView
          show={showGameConfigurationScreen}
          onRequestMatchMaking={onRequestMatchMaking}
          setShow={setShowGameConfigurationScreen}
          selectedGameTypes={selectedGameConfig}
        />
      )}

      {showGameBoard && !selectedGame.mdultiplayer && isFirebaseInitilized && (
        <SinglePlayerGameBoard
          show={showGameBoard}
          selectedGame={selectedGame}
          exitGame={() => {
            // Analytics
            SendAnalyticsToFirebase(
              AnalyticsEventsName.ON_SP_GAME_ITEM_PRESSED
            );
            cancelMatchMaking();
          }}
        />
      )}

      <div className={styles.overflowX}></div>
      <LoadingGIF showLoading={loadingGames} color="#ffffff" size="4x">
        {gameCategoryList.map((gameCategory: GAME_CATEGORY, i: number) => {
          const { games, btn } = checkGamesLength(
            gameCategory.noOfItemsPerRow,
            gameCategory.noOfRows,
            gameCategory.games,
            i
          );
          return (
            <div key={i} style={{ zIndex: 0 }}>
              {i == 0 && <HomeBanner lang={selectedLanguage} />}
              {gameCategory.viewType !== "inviteAndDiscover" &&
                gameCategory.viewType !== "liveTournamnetBanner" && (
                  <>
                    <div
                      className={`text-white 
                        titillium-semibold
                        w-100 
                        d-flex
                        justifty-content-between
                        align-items-center 
                        font-18px`}
                    >
                      <div
                        className="w-100 d-flex"
                        style={{
                          color: theme !== "dark" ? "#1D0141" : "#FFFFFF",
                        }}
                      >
                        {gameCategory.viewType !== "recentPlayed" && (
                          <div className="">
                            {selectedLanguage === "en"
                              ? gameCategory.name
                              : gameCategory.nameAr}
                          </div>
                        )}
                      </div>
                      <div>
                        <Link
                          to={{
                            pathname: `/games-list`,
                            state: gameCategory._id,
                          }}
                          onClick={() => {
                            SendAnalyticsToFirebase(
                              AnalyticsEventsName.MULTIPLAYER_VIEW_MORE_PRESSED(
                                gameCategory.name
                              )
                            );
                          }}
                        >
                          <img
                            src={
                              theme === "dark"
                                ? "/images/ppArrowRight.png"
                                : "/images/ppArrowRightDark.png"
                            }
                            width="16px"
                            height="16px"
                            style={{
                              rotate:
                                selectedLanguage === "en" ? "0deg" : "-180deg",
                            }}
                          />
                        </Link>
                      </div>
                    </div>
                    {!loadingGames && gameCategory.name !== "Single Player" ? (
                      <div
                        className="
                       position-relative
                       overflow-hidden"
                        style={{
                          marginTop: "18px",
                          marginBottom: "32px",
                        }}
                      >
                        <Swiper
                          slidesPerView={"auto"}
                          mousewheel
                          freeMode={{
                            sticky: false,
                            enabled: true,
                            momentum: true,
                            minimumVelocity: 0,
                          }}
                          modules={[FreeMode]}
                          className="mySwiper"
                        >
                          <div
                            className={`d-flex
                              justify-content-start
                              overflow-auto
                              scroll-smooth
                              ${styles.banner}
                            `}
                          >
                            {games.map((game: GAME_ITEM, i) => {
                              return (
                                <SwiperSlide
                                  className="
                                      position-relative
                                      cursor-pointer"
                                  style={{
                                    width: "120px",
                                    height: "auto",
                                  }}
                                  key={i}
                                >
                                  <GameItemWithoutBackground
                                    game={game}
                                    noOfItemsPerRow={
                                      gameCategory?.noOfItemsPerRow
                                    }
                                    onGameClick={playGame}
                                    key={i}
                                    index={i + 1}
                                    selectedLanguage={selectedLanguage}
                                    itemSpace={itemSpace}
                                    gameCategoryDirectionVertical={
                                      gameCategory.viewDirection === "vertical"
                                    }
                                  />
                                </SwiperSlide>
                              );
                            })}
                          </div>
                        </Swiper>
                      </div>
                    ) : (
                      <Swiper
                        className={styles.banner}
                        style={{
                          marginTop: "18px",
                          marginBottom: "32px",
                          display: "flex",
                          flexDirection: "column",
                          flexWrap: "nowrap",
                          height: "100%",
                        }}
                        slidesPerView={"auto"}
                        freeMode={{
                          sticky: false,
                          enabled: true,
                          momentum: true,
                          minimumVelocity: 0,
                        }}
                        modules={[FreeMode]}
                      >
                        {Array.from({ length: 3 }, (_, rowIndex) => (
                          <SwiperSlide
                            key={rowIndex}
                            style={{
                              width: "55%",
                            }}
                            className={`${
                              selectedLanguage === "en" ? "mr-5 " : ""
                            }`}
                          >
                            {games
                              .slice(rowIndex * 3, (rowIndex + 1) * 3)
                              .map((game: GAME_ITEM, i) => (
                                <GameItemGrids
                                  game={game}
                                  noOfItemsPerRow={
                                    gameCategory?.noOfItemsPerRow
                                  }
                                  onGameClick={playGame}
                                  index={rowIndex * 3 + i + 1}
                                  selectedLanguage={selectedLanguage}
                                  itemSpace={itemSpace}
                                  gameCategoryDirectionVertical={
                                    gameCategory.viewDirection === "vertical"
                                  }
                                />
                              ))}
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    )}
                  </>
                )}
            </div>
          );
        })}
      </LoadingGIF>
      <div id="sfakndfaja5sad5155" className="text-center">
        {/* {!persistState.appendListLoaded && loadingGames === false && (
                 <LoadingGIF size="4x"></LoadingGIF>
                 )} */}
      </div>
    </div>
  );
};
