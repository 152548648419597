import { useEffect, useState } from "react";

function getDefaultSystemTheme() {
  return window.matchMedia("(prefers-color-scheme: dark)").matches
    ? "dark"
    : "light";
}

const useTheme = () => {
  const [theme, setTheme] = useState(() => {
    const storedTheme = localStorage.getItem("theme");
    return storedTheme || getDefaultSystemTheme();
  });

  const setAndStoreTheme = (newTheme: any) => {
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
  };

  useEffect(() => {
    const darkModeMediaQuery = window.matchMedia(
      "(prefers-color-scheme: dark)"
    );
    const handleThemeChange = (event: any) => {
      setAndStoreTheme(event.matches ? "dark" : "light");
    };

    darkModeMediaQuery.addEventListener("change", handleThemeChange);

    return () => {
      darkModeMediaQuery.removeEventListener("change", handleThemeChange);
    };
  }, []);

  return { theme, setTheme: setAndStoreTheme };
};

export default useTheme;
export { getDefaultSystemTheme };
