import React, { useEffect, useState } from "react";
import { IStore } from "@Interfaces";
import { Tabs, Tab } from "@material-ui/core";

import { AuthenticationActions } from "@Actions/Authentication";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import EnterNumberPayment from "./EnterNumberPayment";
import { EnterPinPayment } from "./EnterPinPayment";
import PaymentMethod from "./payment-method";
import libphonenumber from "google-libphonenumber";
// import { DCBPaymentInfo } from "@Components/Modals/DCBPaymentInfo"
import "./paywall.css";
import { GenericPopupModal } from "@Components/Modals/GenericPopupModal";
import {
  getRecommendedPaymentMethod,
  getServiceIdFromNumero,
} from "@HelperFunctions";
import {
  AnalyticsEventsName,
  SendAnalyticsToFirebase,
} from "@Services/FirebaseEvents";
import { PaymentMethodType } from "@Constants";
import { SUB_BUNDLES } from "@Services/API/PayWall/SubscriptionBundles";
import { ImageModal } from "@Components/Modals/ImageModal";
import { VideoPopupModal } from "@Components/Modals/VideoPopupModal";
import { v1 as uuidv1 } from "uuid";

const Paywall: React.FunctionComponent<{
  setShowPaywall: (a: boolean) => void;
  setShowSubscriptionSuccessfull: (a: boolean) => void;
}> = ({ setShowPaywall, setShowSubscriptionSuccessfull }) => {
  const lib = libphonenumber.PhoneNumberUtil.getInstance();
  const [tabValue, setTabValue] = useState(0);
  const [packageTabs, setPackageTabs] = useState<any>([]);
  const [paymentTypes, setPaymentTypes] = useState<any>([]);
  const [paymentStep, setPaymentStep] = useState(0);
  const [localTelcoImages, setLocalTelcoImages] = useState<any>([]);
  const [selectedPaymentType, setSelectedPaymentType] = useState<any>();
  const [number, setNumber] = useState("");
  const [numberWithoutCountryCode, setNumberWithoutCountryCode] = useState("");
  const [uuid, setUuid] = useState(uuidv1() + "_gagoweb");
  const [showDCBPaymentPopup, setShowDCBPaymentPopup] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState({
    header: "",
    message: "",
  });
  const [openVideo, setOpenVideo] = useState(false);
  const [showEasyPaisaWaitingScreen, setShowEasyPaisaWaitingScreen] =
    useState(false);
  const [easyPaisaHelpVideo, setEasyPaisaHelpVideo] = useState("");
  const [selectedMethodForVideo, setSelectedMethodForVideo] = useState<
    SUB_BUNDLES | undefined
  >(undefined);
  const {
    selectedLanguage,
    persistFirebaseConfiguration,
    session,
    showSkipOnPaywall,
    paymentBundles,
  } = useSelector((state: IStore) => state.persistState);
  const { userGeoLocation, utmParameters } = useSelector(
    (state: IStore) => state.login
  );

  const [loading, setLoading] = useState(false);
  const [serviceId, setServiceId] = useState(0);
  const [disclaimer, setDisclaimer] = useState("");
  const [payWallBundles, setPaywallBundles] = useState<SUB_BUNDLES[][]>();
  const [showNoBundleText, setShowNoBundleText] = useState<Boolean>(false);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleTabChange = (event: any, value: any) => {
    setTabValue(value);
  };
  const canSkipPawall: any = (window as any).canSkipPawall;
  const showSkip: boolean =
    canSkipPawall === "true" || canSkipPawall === undefined;
  useEffect(() => {
    SendAnalyticsToFirebase(AnalyticsEventsName.PAYWALL_OPENED);
  }, []);
  useEffect(() => {
    if (paymentStep === 0) {
      setNumber("");
      setNumberWithoutCountryCode("");
    }
  }, [paymentStep]);
  // (window as any).onload=setShowPaywall(false);
  useEffect(() => {
    console.log(paymentBundles);
    let paywallBundlesData: SUB_BUNDLES[][] = [];
    if ((window as any).compaignPaymentMethods) {
      //@ts-ignore
      paymentBundles.map((singleBundle) => {
        //@ts-ignore
        let parsedBundles = singleBundle.filter((bundle) =>
          filteredDataAccordingToPVI(
            bundle,
            (window as any).compaignPaymentMethods
          )
        );
        if (parsedBundles && parsedBundles.length > 0) {
          paywallBundlesData.push(parsedBundles);
        }
      });
      if (paywallBundlesData.length === 0) paywallBundlesData = paymentBundles;
      else if (
        paywallBundlesData.length === 1 &&
        paywallBundlesData[0].length === 1
      ) {
        click(paywallBundlesData[0][0]);
      }
    } else {
      paywallBundlesData = paymentBundles;
    }

    setPaywallBundles(paywallBundlesData);
    setShowNoBundleText(paywallBundlesData.length === 0);
  }, []);

  useEffect(() => {
    if ((window as any).isFromRecommendedAction) {
      (window as any).isFromRecommendedAction = false;
      const recommendedBundle = getRecommendedPaymentMethod(paymentBundles);
      if (recommendedBundle) {
        click(recommendedBundle);
      }
    }
  }, []);

  const getUtmParameters = (eventName: string) => {
    const compaign_id =
      utmParameters.compaign_id == null || utmParameters.compaign_id == ""
        ? "182"
        : utmParameters.compaign_id;
    const tracking_code =
      utmParameters.tracking_code == null || utmParameters.tracking_code == ""
        ? uuid
        : utmParameters.tracking_code;
    const compaign_name =
      utmParameters.compaign_name == null || utmParameters.compaign_name == ""
        ? ""
        : utmParameters.compaign_name;
    const medium =
      utmParameters.medium == null || utmParameters.medium == ""
        ? ""
        : utmParameters.medium;

    return {
      event_name: eventName,
      compaign_id: compaign_id,
      tracking_code: tracking_code,
      compaign_name: compaign_name,
      medium: medium,
    };
  };

  const filteredDataAccordingToPVI = (
    singleBundle: SUB_BUNDLES,
    pmSequence: String
  ): Boolean => {
    if (
      singleBundle.paymentType.toLowerCase() === PaymentMethodType.EASYPAISA &&
      (pmSequence.includes("e") || pmSequence.includes("E"))
    )
      return true;
    else if (
      singleBundle.paymentType.toLowerCase() === PaymentMethodType.MOBILE &&
      (pmSequence.includes("m") || pmSequence.includes("M"))
    )
      return true;
    else if (
      singleBundle.paymentType.toLowerCase() === PaymentMethodType.GPLAY &&
      (pmSequence.includes("g") || pmSequence.includes("G"))
    )
      return true;
    return false;
  };

  const sendPin = async (number: string, method: any) => {
    console.log("method", method);
    if (
      number.length !==
      selectedPaymentType.numLimit + selectedPaymentType.operatorCode.length + 1
    ) {
      setShowErrorPopup({
        header: t("SUBSCRIPTION_ERROR"),
        message: t("SUBSCRIPTION_ERROR_MSG"),
      });
      return;
    }
    let serviceId = -1;
    if (method.disclaimers && method.disclaimers.length > 0) {
      serviceId = method.disclaimers[0].serviceId;
    }
    setLoading(true);
    const numberWithoutCode = number.split(
      `${lib.getCountryCodeForRegion(
        persistFirebaseConfiguration.country.country_code.toLowerCase()
      )}`
    );
    setNumberWithoutCountryCode(numberWithoutCode[1]);
    setNumber(number);
    // debugger;
    let userRegion = persistFirebaseConfiguration.regions.find(
      (region: any) =>
        region.countryIso ===
        persistFirebaseConfiguration.country.country_code.toLowerCase()
    );
    // let serviceId = getServiceIdFromNumero(
    //   number.replace("+", ""),
    //   userRegion,
    //   method.package
    // );

    SendAnalyticsToFirebase(
      AnalyticsEventsName.PAY_WITH_METHOD_SEND_PIN_PRESSED(
        method.paymentType,
        method.package
      )
    );

    const sendPinResponse: any = await dispatch(
      AuthenticationActions.SendPin(
        {
          msisdn: number.replace("+", ""),
          serviceId: method.serviceId,
          subscriptionRequired: true,
          region: userGeoLocation ? userGeoLocation.country_code : "",
        },
        selectedLanguage
      )
    );
    if (!sendPinResponse.success) {
      SendAnalyticsToFirebase(
        AnalyticsEventsName.PAY_WITH_METHOD_SEND_PIN_FAILED(
          method.paymentType,
          method.package
        )
      );
      if (sendPinResponse.response_code === 107)
        SendAnalyticsToFirebase(
          AnalyticsEventsName.PAY_WITH_METHOD_SERVICE_NOT_AVAILABLE(
            method.paymentType,
            method.package
          )
        );
      setShowErrorPopup({
        message: sendPinResponse.message,
        header: sendPinResponse.header,
      });
    } else {
      if (serviceId == 37) {
        if (sendPinResponse.data.alreadySub) {
          window.open(
            `https://staging.gago.games/?subscriptionNo=${number.replace(
              "+",
              ""
            )}`,
            "_self"
          );
          return;
        } else {
          window.open(sendPinResponse.data.redirectionUrl, "_self");
        }
      } else {
        setPaymentStep(2);
      }
      // setPaymentStep(2);
      if (sendPinResponse.success && sendPinResponse.data.serviceId) {
        SendAnalyticsToFirebase(
          AnalyticsEventsName.PAY_WITH_METHOD_SEND_PIN_SUCCESS(
            method.paymentType,
            method.package
          )
        );
        setServiceId(sendPinResponse.data.serviceId);

        let service = userRegion.services.find(
          (serv: any) => serv.serviceId === sendPinResponse.data.serviceId
        );
        if (service) {
          let disclaimer = service.disclaimers.find(
            (disc: any) => disc.lang === selectedLanguage
          );
          if (disclaimer) setDisclaimer(disclaimer.disclaimer);
        }
      }
    }
    setLoading(false);

    dispatch(AuthenticationActions.UTMCall(getUtmParameters("send_pin")));
    return sendPinResponse;
  };
  const confirmPin = async (pin: string) => {
    if (
      selectedPaymentType.paymentType.toLowerCase() ===
      PaymentMethodType.EASYPAISA.toLowerCase()
    ) {
      setShowEasyPaisaWaitingScreen(true);
      setEasyPaisaHelpVideo(selectedPaymentType?.helpUrl || "");
    }
    SendAnalyticsToFirebase(
      AnalyticsEventsName.PAY_WITH_METHOD_CONFIRM_PIN_PRESSED(
        selectedPaymentType.paymentType,
        selectedPaymentType.package
      )
    );
    setLoading(true);
    const confirmPinResponse: any = await dispatch(
      AuthenticationActions.ConfirmPin(
        {
          msisdn: number.replace("+", ""),
          serviceId: selectedPaymentType.serviceId,
          pinCode: pin,
        },
        selectedLanguage
      )
    );

    setShowEasyPaisaWaitingScreen(false);
    if (!confirmPinResponse.success) {
      SendAnalyticsToFirebase(
        AnalyticsEventsName.PAY_WITH_METHOD_CONFIRM_PIN_FAILED(
          selectedPaymentType.paymentType,
          selectedPaymentType.package
        )
      );
      setShowErrorPopup({
        message: confirmPinResponse.message,
        header: confirmPinResponse.header,
      });
    } else {
      (window as any).compaignPaymentMethods = null;
      SendAnalyticsToFirebase(
        AnalyticsEventsName.PAY_WITH_METHOD_CONFIRM_PIN_SUCCESS(
          selectedPaymentType.paymentType,
          selectedPaymentType.package
        )
      );
      SendAnalyticsToFirebase(AnalyticsEventsName.PURCHASE);
      if (!confirmPinResponse?.data?.alreadySub) {
        dispatch(AuthenticationActions.UTMCall(getUtmParameters("subscribe")));
      }
      let payloadParams: any = {
        type: selectedPaymentType.paymentType,
        userId: session._id,
        data: number.replace("+", ""),
        subscriptionId: confirmPinResponse?.data?.subscriptionId,
        serviceId: confirmPinResponse?.data?.serviceId,
      };

      setLoading(true);
      const result = await dispatch(
        AuthenticationActions.addPaymentMethod(payloadParams, dispatch)
      );
      setLoading(false);

      setShowPaywall(false);
      setShowSubscriptionSuccessfull(true);
    }

    setLoading(false);
    dispatch(AuthenticationActions.UTMCall(getUtmParameters("confirm_pin")));
    return confirmPinResponse;
  };
  const click = (type: any) => {
    if (type.paymentType === PaymentMethodType.GPLAY) {
      SendAnalyticsToFirebase(
        AnalyticsEventsName.PAY_WITH_DCB_SELECTED(type.package)
      );
      setShowDCBPaymentPopup(true);
      return;
    }
    SendAnalyticsToFirebase(
      AnalyticsEventsName.PAY_WITH_METHOD_SELECTED(
        type.paymentType,
        type.package
      )
    );
    setPaymentStep(1);
    setSelectedPaymentType(type);
  };

  const onVideoPlayClick = (method: SUB_BUNDLES) => {
    console.log(method.helpUrl);
    setOpenVideo(true);
    setSelectedMethodForVideo(method);
  };

  return (
    <div className="d-flex paywall-background flex-column p-4">
      {paymentStep === 0 && (
        <>
          {/* <div className="d-flex mt-4">
            <img
              style={{ width: "40px", height: "40px" }}
              src="/images/paywall/collect_coin.png"
            />
          </div> */}
          <div className="d-flex justify-content-center font-20px text-white">
            {t("SELECT_YOUR_PLAN")}
          </div>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            classes={{
              // indicator: "hide-indicator",
              flexContainer: "tab-button",
            }}
            centered
            TabIndicatorProps={{
              style: {
                backgroundColor: "#E95A24",
              },
            }}
            variant="fullWidth"
          >
            {payWallBundles?.map((servicesArray, index: number) => {
              return (
                <Tab
                  label={
                    selectedLanguage === "en"
                      ? servicesArray[0].titleEng
                      : servicesArray[0].titleAr
                  }
                  key={index}
                  classes={{
                    textColorInherit: "text-default",
                    selected: "full-opaity",
                  }}
                />
              );
            })}
          </Tabs>
          <div className="d-flex justify-content-center align-items-center flex-column">
            {payWallBundles &&
              payWallBundles.length > 0 &&
              payWallBundles[tabValue].map((item: any, index: number) => {
                return (
                  <PaymentMethod
                    method={item}
                    key={index}
                    click={click}
                    onVideoPlay={onVideoPlayClick}
                    packageType={item.package}
                    currentCountryISO={persistFirebaseConfiguration.country.country_code.toLowerCase()}
                  />
                );
              })}
          </div>
          {showNoBundleText && (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ color: "white", height: "100%", fontSize: "22px" }}
            >
              {t("NO_BUNDLE_FOUND")}
            </div>
          )}
          {showSkipOnPaywall && (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ color: "#CECECE", height: "100%" }}
            >
              <span
                className="cursor-pointer "
                onClick={() => {
                  (window as any).compaignPaymentMethods = null;
                  SendAnalyticsToFirebase(AnalyticsEventsName.PAYWALL_SKIPPED);
                  setShowPaywall(false);
                  dispatch(
                    AuthenticationActions.SetUserHasSkippedPaywall(true)
                  );
                }}
              >
                {t("SKIP")}
              </span>
            </div>
          )}
        </>
      )}
      {paymentStep === 1 && (
        <div className="d-flex justify-content-center flex-column ">
          <EnterNumberPayment
            setPaymentStep={setPaymentStep}
            sendPin={sendPin}
            selectedPaymentType={selectedPaymentType}
            loading={loading}
            setNumberWithoutCountryCode={setNumberWithoutCountryCode}
            numberWithoutCountryCode={numberWithoutCountryCode}
            selectedLanguage={selectedLanguage}
            countryCode={persistFirebaseConfiguration.country.country_code.toLowerCase()}
          />
        </div>
      )}
      {paymentStep === 2 && (
        <div className="d-flex justify-content-center flex-column ">
          <EnterPinPayment
            setPaymentStep={setPaymentStep}
            confirmPin={confirmPin}
            number={number}
            loading={loading}
            disclaimer={disclaimer}
            editPhoneNumber={() => {
              setPaymentStep(1);
              SendAnalyticsToFirebase(
                AnalyticsEventsName.PAY_WITH_METHOD_EDIT_PHONE(
                  selectedPaymentType.paymentType,
                  selectedPaymentType.package
                )
              );
            }}
            resendPin={() => {
              setPaymentStep(1);
              SendAnalyticsToFirebase(
                AnalyticsEventsName.PAY_WITH_METHOD_RESEND_PIN(
                  selectedPaymentType.paymentType,
                  selectedPaymentType.package
                )
              );
            }}
          />
        </div>
      )}
      {showDCBPaymentPopup && (
        <GenericPopupModal
          title={t("INSTALL_APP")}
          description={t("PLEASE_INSTALL_APP_FOR_DCB_PAYMENT")}
          showSingleButton={true}
          showInfoIcon={true}
          firstButtonText={t("OK")}
          firstButtonCallback={() => {
            setShowDCBPaymentPopup(false);
          }}
        />
      )}
      {(showErrorPopup.header || showErrorPopup.message) && (
        <GenericPopupModal
          title={showErrorPopup.header}
          description={showErrorPopup.message}
          showSingleButton={true}
          showInfoIcon={true}
          firstButtonText={t("OK")}
          firstButtonCallback={() => {
            setShowErrorPopup({ header: "", message: "" });
          }}
        />
      )}
      {openVideo && selectedMethodForVideo && (
        <ImageModal
          src={selectedMethodForVideo?.helpUrl}
          type="video"
          onClose={() => {
            setSelectedMethodForVideo(undefined);
            setOpenVideo(false);
          }}
        />
      )}
      {showEasyPaisaWaitingScreen && (
        <VideoPopupModal
          description={t("EASY_PAISA_HELP")}
          width={"60%"}
          url={easyPaisaHelpVideo}
        />
      )}
    </div>
  );
};

export default Paywall;
