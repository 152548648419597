import React from "react";
import { BackgroundImage } from "@Styled";
import { useTranslation } from "react-i18next";

export const GameResultImage: React.FunctionComponent<{
  gameResult: string;
}> = ({ gameResult }) => {
  const { t } = useTranslation();
  return (
    <div
      className="d-flex py-3 px-3 w-100 h-100"
      style={{
        backgroundColor: "#32275F",
        backgroundSize: "cover",
        backgroundPosition: "center",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {gameResult === "win" && (
        <>
          <BackgroundImage
            src={["/images/win_2.png"]}
            paddingBottom="74.25%"
            backgroundPosition="center"
            backgroundSize="contain"
            zIndex={1}
          />
          <img
            className="position-absolute resultImageCircle"
            src="/images/circle_new.png"
            style={{ marginTop: "15%", marginLeft: "-30px" }}
            width="50%"
          />
        </>
      )}
      {gameResult === "draw" && (
        <>
          <BackgroundImage
            src={["/images/draw_2.png"]}
            paddingBottom="64.25%"
            backgroundPosition="center"
            backgroundSize="contain"
            zIndex={1}
          />
          <img
            className="position-absolute resultImageCircle"
            src="/images/circle_new.png"
            style={{ marginTop: "15%", marginLeft: "-50px" }}
            width="50%"
          />
        </>
      )}
      {gameResult === "lose" && (
        <>
          <BackgroundImage
            src={["/images/lose_2.png"]}
            paddingBottom="64.25%"
            backgroundPosition="center"
            backgroundSize="contain"
            zIndex={1}
          />
          <img
            className="position-absolute resultImageCircle"
            src="/images/circle_new.png"
            style={{ marginTop: "40px" }}
            width="55%"
          />
        </>
      )}
      <div
        className="d-flex justify-content-center align-items-center position-absolute"
        style={{
          zIndex: -1,
          fontSize: "2em",
          color: "white",
          top: "0",
          bottom: "0",
          left: "0",
          right: "0",
        }}
      >
        {gameResult === "win"
          ? t("You_Win")
          : gameResult === "lose"
          ? t("You_Lose")
          : gameResult === "draw"
          ? t("Draw")
          : ""}
      </div>
    </div>
  );
};
