import React from "react";
import styled, {keyframes} from "styled-components";

import {IGradientLoadingRing} from "./GradientLoadingRing";
import {useSelector} from "react-redux";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Circle = styled.div<IGradientLoadingRing.IProps>`
  width: 100%;
  height: 100%;

  &:after,
  &:before {
    content: "";

    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    width: 100%;
    height: 50%;
  }

  // First half (top) of the cirlce with a left to right gradient
  &:before {
    border-radius: ${({height}) => {
      return height + " " + height + " 0 0";
    }};
    background-image: linear-gradient(to right, #e95a24, #e95a24);
  }

  // Second half (bottom)
  &:after {
    top: 50%;
    border-radius: ${({height}) => {
      return "0 0 " + height + " " + height;
    }};
    background-image: linear-gradient(to right, #e95a24, transparent);
  }

  animation-name: ${rotate};
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
`;
const Container = styled.div<IGradientLoadingRing.IProps>`
  position: relative;
  display: block;
  height: ${({height}) => height};
  width: ${({width}) => width};
  background: linear-gradient(to right, rgb(254, 207, 15, 0), transparent);
  border-radius: 50%;
`;
const Content = styled.div`
  height: 85%;
  width: 85%;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const GradientLoadingRing: React.FunctionComponent<
  IGradientLoadingRing.IProps
> = ({children, ...props}) => {
  const theme = useSelector((state: any) => state.themeReducer.theme);
  return (
    <Container {...props}>
      <Circle {...props}></Circle>
      <Content style={{
        backgroundColor: theme === 'dark' ? '#1d0141' : '#fff'
      }}>{children}</Content>
    </Container>
  );
};
