import React, { useEffect, useState } from "react";
import { IGameItem } from "./IGameItem";
import { LazyImage } from "@Styled/LazyImage";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";

import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";

import { FreeMode, Pagination } from "swiper";
import { getTColor } from "@HelperFunctions";
import useTheme from "../../Hooks/useTheme";
import { useSelector } from "react-redux";

export const GameItemGrids: React.FunctionComponent<IGameItem.IProps> = ({
  game,
  noOfItemsPerRow,
  onGameClick,
  selectedLanguage,
  gameCategoryDirectionVertical,
  itemSpace,
  index,
}) => {
  const uiTheme = useSelector((state: any) => state.themeReducer.theme);

  const getRandomValue = (min: any, max: any) => {
    min = Math.max(0, min);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const [minValue, setminValue] = useState(500);
  const [maxValue, setmaxValue] = useState(1500);

  const [randomValue, setRandomValue] = useState(
    getRandomValue(minValue, maxValue)
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setminValue((prev) => prev + 10);
      setmaxValue((prev) => Math.max(prev - 10, minValue));
      setRandomValue(getRandomValue(minValue, maxValue));
    }, 12000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div
      style={{
        marginBottom: 24,
        display: "flex",
      }}
      onClick={() => {
        onGameClick(game);
      }}
      className="cursor-pointer"
    >
      <SwiperSlide
        className="
      position-relative
      cursor-pointer"
        style={{
          width: "90px",
          height: "90px",
        }}
        key={index}
      >
        <LazyImage
          src={
            game.gifUrl && game.gifUrl !== "false"
              ? game.gifUrl
              : game.thumbnail
          }
          alt={game.title}
          height="90px"
          width="90px"
          borderRadius="15px"
        />
      </SwiperSlide>
      <div
        className="m-2
      ml-3
      d-flex
      flex-column
      align-items-start
      justify-content-between"
      >
        <div
          style={{
            color: uiTheme === "dark" ? "#FFFFFF" : "#1D1540",
            fontWeight: "semi-bold",
            fontSize: "14px",
            display: "inline-block",
          }}
          className="text-truncate
          user-select-none
          "
        >
          {game.title}
        </div>
        <div className="d-flex user-select-none">
          {game.tags?.map((tag: any, index: number) => {
            return (
              index < 3 && (
                <div
                  style={{
                    color: uiTheme === "dark" ? "#FFFFFF" : "#1D1540",
                    fontSize: "12px",
                    marginLeft: index > 0 ? "10px" : "0px",
                  }}
                  key={index}
                >
                  {selectedLanguage === "en"
                    ? tag.en
                    : tag.ar === ""
                    ? tag.en
                    : tag.ar}
                </div>
              )
            );
          })}
        </div>

        <div
          className="d-flex
          user-select-none
        align-items-center"
        >
          <img
            src={
              uiTheme === "dark"
                ? "/images/ppViews.svg"
                : "/images/ppViewsDark.svg"
            }
            width="14px"
            height="14px"
            draggable={false}
          />
          <div
            className="ml-2"
            style={{
              fontSize: "12px",
              color: uiTheme === "dark" ? "#FFFFFF" : "#1D1540",
            }}
          >
            {randomValue}
          </div>
        </div>
      </div>
    </div>
  );
};
