import React, { useEffect, useRef, useState } from "react";
import styles from "./style.module.scss";

import { Swiper, SwiperSlide } from "swiper/react/swiper-react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
// import "swiper/fre";

// import required modules
import { Autoplay, FreeMode, Pagination } from "swiper";
import { GameService } from "@Services/API/Games";
import { IStore, TOURNAMENT_HOME } from "@Interfaces";
import { TournamentActions } from "@Actions/Tournament";
import {
  AnalyticsEventsName,
  SendAnalyticsToFirebase,
} from "@Services/FirebaseEvents";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { HomeActions } from "@Actions/Home";
import { PersistActions } from "@Actions/PersistActions";
import { formatRemainingTime } from "@HelperFunctions";

type Props = {
  data?: any;
  lang?: any;
};

const dummyImg =
  "https://www.riotgames.com/darkroom/1440/d0807e131a84f2e42c7a303bda672789:3d02afa7e0bfb75f645d97467765b24c/valorant-offwhitelaunch-keyart.jpg";
const HomeBanner = (props: Props) => {
  const ref = useRef<any>(null);

  const handleBannerClick = (data: any) => {
    alert(data);
  };

  const { bannerData } = useSelector((state: IStore) => state.persistState);
  const { session: sessionUser } = useSelector(
    (state: IStore) => state.persistState
  );

  const [isReminderSet, setisReminderSet] = useState(false);
  const [cindex, setcindex] = useState<any>(null);

  const setTournamentReminder = async (userId: any, tournamentId: any) => {
    const response = await GameService.GetSetReminder({
      userId,
      tournamentId,
    });

    if (response.success) {
      setisReminderSet(true);
    }
  };

  const getBannersData = async () => {
    await dispatch(PersistActions.setHomeBanners());
  };

  const dispatch = useDispatch();
  const history = useHistory();

  const setTournamentDataAndNavigate = (
    selectedTournament: TOURNAMENT_HOME
  ) => {
    dispatch(TournamentActions.SetSelectedTournament(selectedTournament));
    SendAnalyticsToFirebase(
      AnalyticsEventsName.TOURNAMENT_PRESSED(selectedTournament.name)
    );
    history.push(`/tournament-preplay/${selectedTournament._id}?fromHome=true`);
  };

  useEffect(() => {
    getBannersData();
  }, []);

  const persistState = useSelector((state: IStore) => state.persistState);

  const joinedTournament = Object.keys(
    persistState?.session?.tournaments !== null &&
      persistState?.session?.tournaments !== undefined
      ? persistState?.session?.tournaments
      : []
  );

  const checkUserJoined = (selectedActiveTournament: string) => {
    const joined = joinedTournament?.find(
      (join_id) => join_id === selectedActiveTournament
    );
    return joined ? true : false;
  };

  const isUserAlreadySetReminder = (item: any) => {
    if (item.includes(sessionUser._id)) {
      return true;
    } else return false;
  };

  return (
    <div
      className="
      mt-4
      pb-5
      d-flex 
      position-relative"
    >
      <Swiper
        slidesPerView={1}
        centeredSlides={true}
        modules={[Autoplay]}
        grabCursor={true}
        autoplay={{ delay: 4000, disableOnInteraction: false }}
        className="mySwiper d-flex"
      >
        <div
          className={`d-flex 
        position-relative
        overflow-auto
        scroll-smooth
        ${styles.banner}`}
        >
          {bannerData?.map((image: any, index: number) => (
            <SwiperSlide
              className={`
              ${props.lang === "en" ? "mr-0" : "ml-0"} d-flex`}
              key={index}
              // onClick={() => handleBannerClick(image)}
            >
              <>
                <div
                  className={`position-absolute 
                flex-column 
                d-flex
                h-100
                align-items-start 
                justify-content-center`}
                  style={{
                    left: "22px",
                  }}
                >
                  <div
                    className="
                  d-flex
                  justify-content-start
                  align-items-start
                  flex-column"
                  >
                    <div className="text-white">{image?.bannerType}</div>
                    <div
                      className="text-white mt-1"
                      style={{
                        fontWeight: "bold",
                        fontSize: "24px",
                      }}
                    >
                      {image?.tournamentId?.name}
                    </div>
                    <div
                      className="d-flex mt-2
                      align-items-center 
                      justify-content-between"
                    >
                      <div
                        className={`d-flex
                      ${props.lang === "en" ? "" : "flex-row-reverse"}
                      align-items-center`}
                      >
                        <img
                          src="/images/bannerTime.svg"
                          width="15px"
                          height="15px"
                          className="mr-2"
                        />
                        <div
                          className="text-white mr-2"
                          style={{
                            fontSize: "10px",
                          }}
                        >
                          {image?.tournamentId?.upComing
                            ? "Starts on"
                            : "Ends On"}
                        </div>
                        <div
                          className="text-white"
                          style={{
                            fontSize: "10px",
                          }}
                        >
                          {new Date(
                            image?.tournamentId?.upComing
                              ? image?.tournamentId?.startTime
                              : image?.tournamentId?.expireTime
                          ).toDateString()}
                          {" | "}
                          {new Date(
                            image?.tournamentId?.upComing
                              ? image?.tournamentId?.startTime
                              : image?.tournamentId?.expireTime
                          ).toLocaleTimeString()}
                        </div>
                      </div>
                    </div>
                  </div>
                  {(isUserAlreadySetReminder(image?.tournamentId?.users) &&
                    image?.tournamentId?.upComing) ||
                  (isReminderSet && cindex === index) ? (
                    <div
                      style={{
                        color: "#E95A24",
                        fontSize: "12px",
                        marginTop: "12px",
                      }}
                    >
                      {formatRemainingTime(
                        new Date(image?.tournamentId?.startTime)
                      )}
                    </div>
                  ) : (
                    <div
                      className="mt-3 cursor-pointer"
                      style={{
                        width: "90px",
                        height: "30px",
                        backgroundColor:
                          image?.tournamentId?.upComing &&
                          !checkUserJoined(image.tournamentId._id)
                            ? "#EEC430"
                            : checkUserJoined(image.tournamentId._id)
                            ? "#E95A24"
                            : "#49A504",
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        borderRadius: "6px",
                        fontSize: "12px",
                      }}
                      onClick={() => {
                        if (
                          image?.tournamentId.upComing &&
                          !checkUserJoined(image.tournamentId._id)
                        ) {
                          setTournamentReminder(
                            sessionUser._id,
                            image?.tournamentId?._id
                          );
                          setcindex(index);
                        } else {
                          setTournamentDataAndNavigate(image?.tournamentId);
                        }
                      }}
                    >
                      {image?.tournamentId.upComing &&
                      !checkUserJoined(image.tournamentId._id)
                        ? "Set Reminder"
                        : checkUserJoined(image.tournamentId._id)
                        ? "Play Now"
                        : "Join Now"}
                    </div>
                  )}
                </div>
                <img
                  src={image?.bannerURL || dummyImg}
                  className="rounded-3"
                  style={{
                    borderRadius: 20,
                    width: "100%",
                    objectFit: "fill",
                  }}
                />
              </>
            </SwiperSlide>
          ))}
        </div>
      </Swiper>
    </div>
  );
};

export default HomeBanner;
