import {
  AccessResponseStatus,
  InviteActionsEnum,
  InviteStatusEnums,
  MessageStatus,
  MessageType,
  PaymentMethodType,
  SubscriptionResponseCode,
} from "@Constants";
import {
  GAME_CONFIG,
  GAME_INVITE,
  GAME_ITEM,
  IStore,
  MESIBO_CHAT_MESSAGE,
  USER_SESSION,
} from "@Interfaces";
import { SUB_BUNDLES } from "@Services/API/PayWall/SubscriptionBundles";
import { VerificationScreen } from "@Services/API/VerificationScreens/VerificationScreen";
import { removeCookie } from "@Services/Cookie";
import { ExclusiveFeaturesActions } from "@Actions/ExclusiveFeatures";
import { useDispatch, useSelector } from "react-redux";
import { ConversationActions } from "@Actions/Conversation";
import { HomeActions } from "@Actions/Home";
import { addInviteOnFirebase } from "@Services/FirebaseEvents";
import { Dispatch } from "redux";
import { MultiGamePlayAction } from "@Actions/MultiGamePlayAction";
import React, { useState, useEffect } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

var CryptoJS = require("crypto-js");
export const lockOppener = "my-secret-key@122";

export const changePageTitle = (
  selectedLanguage: string,
  selectedGameToPlay: string
) => {
  document.title =
    selectedLanguage === "en"
      ? `${selectedGameToPlay} – Playpal`
      : `${selectedGameToPlay} – Playpal`;
};
export const getTColor = () => {
  return localStorage.getItem("theme") === "dark" ? "#FFFFFF" : "#1D1540";
};
export const getTColor2 = () => {
  return localStorage.getItem("theme") !== "dark" ? "#1D0141" : "#FFFFFF";
};
export const getBC1Color = () => {
  return localStorage.getItem("theme") !== "dark" ? "#FFFFFF" : "#161032";
};
export const getBC2Color = () => {
  return localStorage.getItem("theme") === "dark" ? "#1D0141" : "#FFFFFF";
};
export const getBC3Color = () => {
  return localStorage.getItem("theme") === "dark" ? "#1D1540" : "#FFFFFF";
};

export const getBC4Color = () => {
  return localStorage.getItem("theme") === "dark" ? "#261C52" : "#FFFFFF";
};

export const getThemeString = () => {
  if (localStorage.getItem("theme")) {
    return localStorage.getItem("theme");
  } else if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
    return "dark";
  }
  return "light";
};
export const changePageTitleToDefault = (selectedLanguage: string) => {
  document.title =
    selectedLanguage === "en"
      ? "Playpal – Play Games & Talk Live"
      : "Playpal - ألعاب أونلاين و وتحدث معهم";
};

export const encryptData = (data: string) => {
  let encryptString = "";
  try {
    encryptString = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      lockOppener
    ).toString();
  } catch (error) {
    encryptString = data;
  }

  return encryptString;
};

export const decryptData = (data: string) => {
  let decryptedData = "";
  try {
    var bytes = CryptoJS.AES.decrypt(data, lockOppener);
    decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    decryptedData = data;
  }

  return decryptedData;
};

export const clearAppCache = () => {
  if ("caches" in window) {
    caches.keys().then((names) => {
      // Delete all the cache files
      names.forEach((name) => {
        caches.delete(name);
      });
    });

    removeCookie("api-token");
    removeCookie("accpted-terms-conditions");
    // Makes sure the page reloads. Changes are only visible after you refresh.
    window.location.reload();
  }
};

//FOR SAFARI   To record Voice Messages, Playpal needs access to your computer's microphone. Open your browser's Preferences -> websites and change microphone setting for Playpal.games.com to "Allow".
//FOR Chrome   To record Voice Messages, Playpal needs access to your computer's microphone. Click blocked Image in the URL bar and choose "Allways allow Playpal.games.com to access your microphone".
//FOR Firefox   To record Voice Messages, Playpal needs access to your computer's microphone. Click Lock Image in the URL bar and set "Use the Microphone" to "Allow".

export const getMircophonePermissionDescription = () => {
  if (navigator.userAgent.indexOf("Chrome") != -1) {
    return 'To record Voice Messages, Playpal needs access to your computer\'s microphone. Click blocked Image in the URL bar and choose "Allways allow Playpal.games.com to access your microphone".';
  } else if (navigator.userAgent.indexOf("Safari") != -1) {
    return "To record Voice Messages, Playpal needs access to your computer's microphone. Open your browser's Preferences -> websites and change microphone setting for Playpal.games.com to \"Allow\".";
  } else if (navigator.userAgent.indexOf("Firefox") != -1) {
    return 'To record Voice Messages, Playpal needs access to your computer\'s microphone. Click Lock Image in the URL bar and set "Use the Microphone" to "Allow".';
  }
  return 'To record Voice Messages, Playpal needs access to your computer\'s microphone. Click blocked Image in the URL bar and choose "Allways allow Playpal.games.com to access your microphone".';
};

export const praseImageNameToS3Url = (imageName: string) => {
  return (
    process.env.REACT_APP_Playpal_S3_URL +
    "/profileImages/" +
    parseImageUrl(imageName)
  );
};

export const truncateString = (str: string, desiredLength: number) => {
  return str?.length > desiredLength
    ? str.substr(0, desiredLength - 1) + "..."
    : str;
};
export const getProfileImage = (user: any, friendsProfiles?: boolean) => {
  if (user?.userImageUrl) return user.userImageUrl;

  if (user?.isBot) {
    return parseImageUrl(user.profileImage);
  } else {
    if (user?.fb_image) {
      return (
        "https://graph.facebook.com/" +
        user.fb_image +
        "/picture?height=240&width=240"
      );
    } else
      return user?.profileImage
        ? process.env.REACT_APP_Playpal_S3_URL +
            "/profileImages/" +
            parseImageUrl(user.profileImage)
        : "/images/dummay.png";
  }
};

export const formatRemainingTime = (endTime: any) => {
  const now: any = new Date();
  const endDate: any = new Date(endTime);

  let timeDiff = endDate - now;

  if (timeDiff < 0) {
    return "Started";
  }

  const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  timeDiff -= days * (1000 * 60 * 60 * 24);

  const hours = Math.floor(timeDiff / (1000 * 60 * 60));
  timeDiff -= hours * (1000 * 60 * 60);

  const minutes = Math.floor(timeDiff / (1000 * 60));
  timeDiff -= minutes * (1000 * 60);

  const seconds = Math.floor(timeDiff / 1000);

  return `${String(days).padStart(2, "0")}d : ${String(hours).padStart(
    2,
    "0"
  )}hr : ${String(minutes).padStart(2, "0")}m : ${String(seconds).padStart(
    2,
    "0"
  )}s`;
};

const parseImageUrl = (url: string) => {
  if (url) {
    return url.split("?")[0];
  } else return "";
};

export const getFormattedDate = (date: any) => {
  if (date) {
    let d = new Date(date);
    return (
      ("0" + d.getDate()).slice(-2) +
      "/" +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "/" +
      d.getFullYear()
    );
  } else return "";
};
export const addMilliSecondsToCurrentDate = (miliseconds: number) => {
  let date = new Date();
  let newDate;
  if (miliseconds) {
    newDate = new Date(date.getTime() + miliseconds);
    return newDate.getTime();
  }
  return date.getTime();
};
declare global {
  interface Window {
    JitsiMeetExternalAPI: any;
  }
}
export const importJitsiApi = (): Promise<void> =>
  new Promise(async (resolve) => {
    if (window.JitsiMeetExternalAPI) {
      resolve(window.JitsiMeetExternalAPI);
    } else {
      const head = document.getElementsByTagName("head")[0];
      const script = document.createElement("script");

      script.setAttribute("type", "text/javascript");
      script.setAttribute(
        "src",
        "https://voip.Playpal.games/libs/external_api.min.js"
      );

      head.addEventListener(
        "load",
        function (event: any) {
          if (event.target.nodeName === "SCRIPT") {
            resolve(window.JitsiMeetExternalAPI);
          }
        },
        true
      );

      head.appendChild(script);
    }
  });

export const getName = (user: any): string => {
  if (user) {
    if (!user.name) {
      try {
        let phoneTypeArray = user.loginType.filter(
          (data: any) => data.type.toLowerCase() === "phone"
        );
        let phone =
          phoneTypeArray && phoneTypeArray.length > 0
            ? phoneTypeArray[0].id
            : "1234567891011";
        let dummyName = phone.slice(4) + phone.slice(phone.length - 4);

        let sum = dummyName
          .split("")
          .map(Number)
          .reduce(function (a: number, b: number) {
            return a + b;
          });
        return "user " + sum;
      } catch (err) {
        return "user 18634";
      }
    } else return user.name;
  } else return "user 1234";
};
export const isScriptLoaded = (scriptURL: string): boolean => {
  let doc = document.querySelector('script[src="' + scriptURL + '"]');
  if (doc) return true;
  return false;
};
export const getNumberInInternational = (userNummber: string): string => {
  const networkCodes = ["0", "1", "2", "3", "4", "5"];
  if (userNummber?.length === 11 && userNummber?.substring(0, 2) === "03") {
    userNummber = userNummber.substring(1);
    userNummber = "92" + userNummber;
  } else if (
    userNummber?.length === 10 &&
    userNummber?.substring(0, 1) === "3" &&
    networkCodes?.some((network) =>
      userNummber?.substring(1, 2).includes(network)
    )
  ) {
    userNummber = "92" + userNummber;
  }
  return userNummber;
};
export const getServiceIdFromNumero = (
  interNationalFormatNumber: string,
  userRegion: any,
  packageType: string
) => {
  let serviceId = -1;
  try {
    if (userRegion) {
      let numeroInitial = interNationalFormatNumber.substring(
        userRegion.numeroSequence.start,
        userRegion.numeroSequence.end
      );

      userRegion.services.forEach((service: any) => {
        if (
          service.operatorNumeroValues.includes(numeroInitial) &&
          (packageType && packageType.length > 0
            ? service.package.toLowerCase() === packageType.toLowerCase()
            : true)
        ) {
          serviceId = service.serviceId;
        }
      });
    }
  } catch (error) {}
  console.log("Numero Service Id IS ", serviceId);
  return serviceId;
};
export const getServiceIdByCompaign = (
  paymentMethod: string,
  userRegion: any
) => {
  let serviceId = -1;
  if (paymentMethod === "esp") {
    userRegion.services.forEach((service: any) => {
      if (service.operator === "easypaisa") {
        serviceId = service.serviceId;
      }
    });
  }
  return serviceId;
};
export const getMessageAccordingToSubscriptionResponseCode = (
  response_code: number
): string => {
  // if (response_code == SubscriptionResponseCode.AUTHORIZATION_ERROR)
  //   return AppSrings.AUTHORIZATION_ERROR;
  // else if (response_code == SubscriptionResponseCode.INTERNAL_SERVER_ERROR)
  //   return AppSrings.INTERNAL_SERVER_ERROR;
  // else if (response_code == SubscriptionResponseCode.INVALID_OTP)
  //   return AppSrings.INVALID_OTP;
  // else if (response_code == SubscriptionResponseCode.OTP_EXPIRED)
  //   return AppSrings.OTP_EXPIRED;
  // else if (response_code == SubscriptionResponseCode.OPERATOR_UNAVILABLE)
  //   return AppSrings.OPERATOR_UNAVAILABLE;
  // else if (response_code == SubscriptionResponseCode.INVALID_PARAMETERS)
  //   return AppSrings.INVALID_PARAMERTERS;
  // else if (response_code == SubscriptionResponseCode.LAST_OTP_SENT_WAIT)
  //   return AppSrings.LAST_OTP_WAIT;
  if (response_code === SubscriptionResponseCode.INVALID_OTP)
    return "INVALID_OTP";
  // else if (response_code == SubscriptionResponseCode.MSISDN_BLACKLISTED)
  //   return AppSrings.MSISDN_BLACKLISTED;
  return "";
};

export const getHeaderAccordingToSubscriptionResponseCode = (
  response_code: number
): string => {
  // if (response_code == SubscriptionResponseCode.AUTHORIZATION_ERROR)
  //   return AppSrings.AUTHORIZATION_ERROR_HEADER;
  // else if (response_code == SubscriptionResponseCode.INTERNAL_SERVER_ERROR)
  //   return AppSrings.INTERNAL_SERVER_ERROR_HEADER;
  // else if (response_code == SubscriptionResponseCode.INVALID_OTP)
  //   return AppSrings.INVALID_OTP_HEADER;
  // else if (response_code == SubscriptionResponseCode.OTP_EXPIRED)
  //   return AppSrings.OTP_EXPIRED_HEADER;
  // else if (response_code == SubscriptionResponseCode.OPERATOR_UNAVILABLE)
  //   return AppSrings.OPERATOR_UNAVAILABLE_HEADER;
  // else if (response_code == SubscriptionResponseCode.INVALID_PARAMETERS)
  //   return AppSrings.INVALID_PARAMERTERS_HEADER;
  // else if (response_code == SubscriptionResponseCode.LAST_OTP_SENT_WAIT)
  //   return AppSrings.LAST_OTP_WAIT_HEADER;
  if (response_code === SubscriptionResponseCode.INVALID_OTP)
    return "INVALID_OTP_HEADER";
  // else if (response_code == SubscriptionResponseCode.MSISDN_BLACKLISTED)
  //   return AppSrings.MSISDN_BLACKLISTED_HEADER;
  return "";
};

export const isPaidContentAllowed = (user: USER_SESSION) => {
  // accessBlockReason 0 = "no reason needed", 1 = "low balance", 2 = "No active subscription"

  return {
    accessAllowed: true,
    accessBlockReason: AccessResponseStatus.NO_STATUS,
  };

  // if (!user._id) {
  //   return {
  //     accessAllowed: false,
  //     accessBlockReason: AccessResponseStatus.USER_NOT_FOUND,
  //   };
  // }

  // if (!user.purchaseStatus && (getUserActivePaymentMethod(user).length>0)) {
  //   return {accessAllowed: true, accessBlockReason: AccessResponseStatus.NO_STATUS}
  // }

  // if (
  //   (user.purchaseStatus &&
  //     user.purchaseStatus.response_code ===
  //       SubscriptionResponseCode.ACTIVE_SUB) ||
  //   (user.purchaseStatus &&
  //     user.purchaseStatus.response_code ===
  //       SubscriptionResponseCode.LOW_BALANCE &&
  //     user.onTrial)
  // ) {
  //   return {
  //     accessAllowed: true,
  //     accessBlockReason: AccessResponseStatus.NO_STATUS,
  //   };
  // } else if (
  //   user.purchaseStatus &&
  //   user.purchaseStatus.response_code === SubscriptionResponseCode.LOW_BALANCE
  // ) {
  //   return {
  //     accessAllowed: false,
  //     accessBlockReason: AccessResponseStatus.LOW_BALANCE,
  //   };
  // } else {
  //   return {
  //     accessAllowed: false,
  //     accessBlockReason: AccessResponseStatus.NO_SUBSCRIPTION,
  //   };
  // }
};

export const getCheckStatusCallParams = (
  session: USER_SESSION,
  subscriptionNo: string
) => {
  const data = getUserActivePaymentMethod(session);
  const isGPlayToken =
    data && data.type.toLowerCase() === PaymentMethodType.GPLAY.toLowerCase();
  const params = data
    ? isGPlayToken
      ? { gPlayPurchaseToken: data.data }
      : { msisdn: data.data }
    : getNumberInInternational(subscriptionNo)
    ? { msisdn: getNumberInInternational(subscriptionNo) }
    : null;
  console.log("getCheckStatusCallParams", params);
  return params;
};

export const getUserActivePaymentMethod = (session: USER_SESSION) => {
  const data = session.paymentMethods?.filter((e) => e.active === true);
  return data && data.length > 0 ? data[0] : null;
};

export const getRecommendedPaymentMethod = (
  bundles: Array<Array<SUB_BUNDLES>>
): SUB_BUNDLES | null => {
  let result: SUB_BUNDLES | null = null;
  bundles.forEach((bundleArray) => {
    const bundle = bundleArray.find(
      (element) => element.isRecommended === true
    );
    if (bundle) {
      result = bundle;
      return result;
    }
  });

  return result;
};

export const isServiceIdExists = (
  bundles: Array<Array<SUB_BUNDLES>>,
  serviceId: number
): Boolean => {
  let result: Boolean = false;
  bundles.forEach((bundleArray) => {
    const bundle = bundleArray.find(
      (element) => element.serviceId === serviceId
    );
    if (bundle) {
      result = true;
    }
  });

  return result;
};

export const haveSpecialUIConfig = (data?: VerificationScreen) => {
  return data && data.isSpecialView === true;
};

/**
 * setViewPortSize
 *
 * @param {string} toFull Change viewport to full screen if true
 * @param {string} orientaion current orientation of device
 * @param {string} width Change width of viewport to specifed width if toFull is false
 * @param {string} height Change width of viewport to specifed height if toFull is false
 * @param {string} maxWidth Change maxWidth of viewport to specifed width if toFull is false
 */
export const setViewPortSize = (
  toFull: boolean,
  orientaion: string = "",
  width: number = 600,
  height: number = 600,
  maxWidth: number = 600
) => {
  console.log("viewport set is called: ", toFull, width, height, maxWidth);
  let vpc = document.getElementById("view-port-container");
  let root = document.getElementById("root");
  if (root) {
    // console.log("root : ", root)
    // console.log("root style: ", root.style)
    console.log("root width: ", document.body.clientWidth);
    console.log("root height: ", document.body.clientHeight);
  }
  if (vpc) {
    console.log("outer: ", window.outerWidth);
    console.log("inner: ", window.innerWidth);
    let w = toFull ? document.body.clientWidth : width;
    let h = toFull ? document.body.clientHeight : height;
    let mW = toFull ? document.body.clientWidth : maxWidth;

    // let w = toFull ?  window.outerWidth : width ;
    // let h = toFull ?  window.outerHeight  : height;
    // let mW = toFull ?  window.outerWidth  : maxWidth;

    let max = Math.max(w, h);

    // max.toString() + "px"
    vpc.style.width = orientaion === "landscape" ? "100%" : w.toString() + "px";
    vpc.style.maxWidth =
      orientaion === "landscape" ? "100%" : mW.toString() + "px";
  }
};
export const getServiceofRegionIfAny = (countryIso: string, regions: any) => {
  let specifiedRegion: any = null;
  if (regions.length > 0) {
    regions.map((region: any) => {
      if (region.countryIso == countryIso) {
        specifiedRegion = region;
      }
    });
  }
  if (specifiedRegion) {
    if (specifiedRegion.services.length > 0) {
      return specifiedRegion.services[0];
    }
  }
};
// export const showExclusiveFeatureModal=(flag:boolean)=>{
//   const dispatch = useDispatch();
//   dispatch(
//     ExclusiveFeaturesActions.SetExclusiveFeature(flag)
//   )
// }

const playersArrayMapping = (players: any) => {
  return players?.map((player: any, index: number) => {
    const { id, n, uI, b, t } = player;
    console.log(player);
    return {
      playerId: id,
      playerNumber: index,
      name: n,
      avatarUrl: uI,
      isBot: b,
      teamId: parseInt(t.substr(t.length - 1)),
      socketId: "",
      currentGame: {},
    };
  });
};
export const matchMakingResponseMapping = (data: any) => {
  const players = playersArrayMapping(data.ps);
  const { rId, gId, mId, s } = data;
  return {
    roomId: rId,
    gameId: gId,
    matchId: mId,
    players: players,
    status: s,
    other_user: [{}],
  };
};

export const getPositionSupText = (i: number) => {
  let j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return "st";
  }
  if (j === 2 && k !== 12) {
    return "nd";
  }
  if (j === 3 && k !== 13) {
    return "rd";
  }
  return "th";
};

export const sendGameInvite = async (
  game: GAME_ITEM,
  user: USER_SESSION,
  dispatch: Dispatch<any>,
  other_user?: USER_SESSION | any,
  id?: string
) => {
  const ts = Date.now();
  const messageId = ts.toString().substring(0, 10);
  //invite_other_user = other_user;
  const playAgain: any = await dispatch(
    HomeActions.PlayAgain({
      pid1: user._id,
      pid2: other_user._id ? other_user._id : id,
      gameId: game.gameId,
      acceptInvite: false,
    })
  );

  if (playAgain.response && playAgain.success) {
    const inviteMsg: GAME_INVITE = {
      _gameId: game.gameId,
      _pid1: user._id,
      _pid2: other_user._id ? other_user._id : id,
      _pid2_name: other_user.name,
      _pid2_profileImage: getProfileImage(other_user),
      created_at: ts.toString(),
      expire_at: (ts + 30 * 1000).toString(),
      inviteStatus: InviteStatusEnums.PENDING,
      inviteActions: other_user.isBot
        ? InviteActionsEnum.ACCEPTED
        : InviteActionsEnum.DEFAULT,
      // inviteActions: InviteActionsEnum.DEFAULT
    };

    const mesiboMessage: MESIBO_CHAT_MESSAGE = {
      _id: messageId,
      type: MessageType.INVITE,
      message: JSON.stringify(inviteMsg),
      sender: user._id,
      receiver: other_user._id ? other_user._id : id,
      messageStatus: MessageStatus.PENDING,
      timeStamp: ts,
      roomId: other_user._id ? other_user._id : id,
      previousMid: "",
      ownerId: user._id,
    };
    let key = addInviteOnFirebase(
      user._id,
      other_user._id ? other_user._id : id,
      {
        createdTime: Date.now(),
        gameId: game.gameId,
        roomId: playAgain.response.roomDetails.roomId,
        sentBy: user._id,
        status: "invited",
      },
      false
    );
    mesiboMessage._id = key;
    dispatch(
      ConversationActions.SetConversation([
        { message: JSON.stringify(mesiboMessage) },
      ])
    );
    // let mesibo: any = await MesiboApi().getInstance();
    // const messageSent = mesibo.sendMessage({ peer: other_user._id, flag: 'MESIBO_FLAG_DEFAULT', sender: user._id },
    //     messageId,
    //     JSON.stringify(mesiboMessage)
    // );
    // if (messageSent) {
    //     // readMesiboMessages(mesibo, true);
    //     // setMessageSent(true);

    //     // Analytics
    //     SendAnalyticsToFirebase(AnalyticsEventsName.ON_INVITE_SENT);
    // }
    // else {
    //     let m = (mesiboMessage as any);
    //     // m.AcceptedOfflineGameInvite = true;
    //     BroadcastService().initInstance().postMessage(m);
    // }
  } else {
    console.log("Couldn't send match making request at this time.");
  }
};

export const inputLength = (input: string) => {
  return input.replace(/\s/g, "").length;
};

// const sendGameInvitea = async (game: GAME_ITEM) => {
//   const ts = addMilliSecondsToCurrentDate(30000);
//   console.log(ts);
//   const messageId = ts.toString().substring(0, 10);
//
//   const playAgain: any = await dispatch(HomeActions.PlayAgain({
//     pid1: user._id,
//     pid2: conversationOtherUser._id,
//     gameId: game.gameId,
//     acceptInvite: false
//   }));
//
//   if (playAgain.response && playAgain.success) {
//     const inviteMsg: GAME_INVITE = {
//       _gameId: game.gameId,
//       _pid1: user._id,
//       _pid2: conversationOtherUser._id,
//       _pid2_name: conversationOtherUser.name,
//       _pid2_profileImage: getProfileImage(conversationOtherUser),
//       created_at: ts.toString(),
//       expire_at: (ts + 30 * 1000).toString(),
//       inviteStatus: InviteStatusEnums.PENDING,
//       inviteActions: conversationOtherUser.isBot ? InviteActionsEnum.ACCEPTED : InviteActionsEnum.DEFAULT
//       // inviteActions: InviteActionsEnum.DEFAULT
//     }
//
//     const mesiboMessage: MESIBO_CHAT_MESSAGE = {
//       _id: messageId,
//       type: MessageType.INVITE,
//       message: JSON.stringify(inviteMsg),
//       sender: user._id,
//       receiver: conversationOtherUser._id,
//       messageStatus: MessageStatus.PENDING,
//       timeStamp: ts,
//       roomId: roomId,
//       previousMid: '',
//       ownerId: user._id
//     }
//     let key = addInviteOnFirebase(user._id, conversationOtherUser._id, {
//       createdTime: Date.now(),
//       gameId: game.gameId,
//       roomId: roomId,
//       sentBy: user._id,
//       status: "invited"
//     }, false)
//     mesiboMessage._id = key;
//     dispatch(ConversationActions.SetConversation([{ message: JSON.stringify(mesiboMessage) }]));
//     /// updateInviteEventListener(mesiboMessage, conversationOtherUser._id);
//     // let mesibo: any = await MesiboApi().getInstance();
//     // const messageSent = mesibo.sendMessage({ peer: conversationOtherUser._id, flag: 'MESIBO_FLAG_DEFAULT', sender: user._id },
//     //     messageId,
//     //     JSON.stringify(mesiboMessage)
//     // );
//     // if (messageSent) {
//     //     readMesiboMessages(mesibo, true);
//     //     setMessageSent(true);
//
//     //     // Analytics
//     //     SendAnalyticsToFirebase(AnalyticsEventsName.ON_INVITE_SENT);
//     // }
//     // else {
//     //     let m = (mesiboMessage as any);
//     //     // m.AcceptedOfflineGameInvite = true;
//     //     BroadcastService().initInstance().postMessage(m);
//     // }
//   } else {
//     console.log("Couldn't send match making request at this time.");
//   }
//
//   // const messageId = Date.now().toString().substring(0, 10);
//   // let p: any = {
//   //     peer: conversationOtherUser._id,
//   //     expiry: 3600 * 24
//   // };
//
//   // let msg: any = JSON.stringify({
//   //     type: MessageType.INVITE,
//   //     sender: user._id,
//   //     game: updatedGame,
//   //     flag: "MESIBO_FLAG_DELIVERYRECEIPT|MESIBO_FLAG_READRECEIPT",
//   //     id: messageId
//   // }); //create a rich message
//   // let mesibo: any = await MesiboApi().getInstance();
//   // const messageSent = mesibo.sendMessage(p, messageId, msg);
//   // if (messageSent) {
//   //     readMesiboMessages(mesibo, true);
//   //     setMessageSent(true);
//   // }
// }

export const getConfigurations = (
  game: GAME_ITEM,
  gameConfigJson: GAME_CONFIG[],
  dispatch: Dispatch<any>
) => {
  let selectedGameTypes: GAME_CONFIG[] = [];
  let selectedGameTypesIds = game.configurations;
  // need to uncomment the maps, to be changed

  selectedGameTypesIds?.map((id: string) => {
    gameConfigJson.map((config: GAME_CONFIG) => {
      // to be changed
      if (id === config.id) selectedGameTypes.push(config);
    });
    // selectedGameTypes = gameConfigJson;
    // console.log('selectedGameTypesIds ***************', selectedGameTypes)
    dispatch(MultiGamePlayAction.SetSelectedGameToPlay(game));
    dispatch(MultiGamePlayAction.SetSelectedGameConfig(selectedGameTypes));
    if (selectedGameTypes.length === 1) {
      dispatch(
        MultiGamePlayAction.SetSpecificSelectedConfiguration(
          selectedGameTypes[0].matchMakingTemplate
        )
      );
    }
  });
};
export const tsToProperMessageDate = (
  timestamp: number,
  t: (input: string) => string
) => {
  let messageDate = new Date(timestamp);
  let currentDate = new Date();
  let dateTagToBe = "";

  if (
    currentDate.getMonth() === messageDate.getMonth() &&
    currentDate.getFullYear() === messageDate.getFullYear()
  ) {
    if (currentDate.getDate() - messageDate.getDate() === 1) {
      return t("YESTERDAY");
    } else if (currentDate.getDate() - messageDate.getDate() === 0) {
      return t("TODAY");
    }
  }

  return (
    messageDate.getDate() +
    "-" +
    messageDate.getMonth() +
    "-" +
    messageDate.getFullYear().toString()
  );
};

export const getAMPMTime = (timeStamp: number) => {
  let date = new Date(timeStamp);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  let minutesStr = minutes < 10 ? "0" + minutes : minutes;
  let strTime = hours + ":" + minutesStr + " " + ampm;
  return strTime;
};

export function getLastMessageDeliveryStatus(
  timestamp: number,
  t: (input: string) => string
) {
  const date = tsToProperMessageDate(timestamp, t);
  if (date === t("TODAY")) {
    return getAMPMTime(timestamp).toLocaleLowerCase();
  }
  return date;
}

export const addLeadingZero = (digit: number) => {
  return ("0" + digit).slice(-2);
};

export const isZongSignatureVerfied = async (
  data: string,
  signature: string
) => {
  let url =
    "https://cifb42aqmzza6zas4jdwxycvnu0lfnwl.lambda-url.ap-southeast-1.on.aws/";

  signature = encodeURIComponent(signature);

  url = url + "?signature=" + signature + "&payload=" + data;

  try {
    let response: any = await fetch(url);
    response = await response.json();

    if (response) {
      return {
        token: response.token,
        verificationStatus: response.verificationStatus,
      };
    }
  } catch {
    console.log("err");
  }

  return {
    token: "",
    verificationStatus: false,
  };
};

export const CircularWithValueLabel: React.FunctionComponent = () => {
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 10 ? 0 : prevProgress + 1
      );
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div style={{ width: "8.5vh", height: "8.5vh" }}>
      <CircularProgressbar
        value={progress}
        minValue={0}
        maxValue={10}
        text={`${progress < 10 ? `0${progress}` : progress}`}
        styles={buildStyles({
          pathColor: `#E95A24`,
          textColor: "white",
          trailColor: "#1D0141",
          textSize: "28px",
        })}
      />
    </div>
  );
};
