import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { IStore } from "@Redux/IStore";
import { StaticImage } from "@Styled";
import { USER_SESSION } from "@Interfaces";
import { SendAnalyticsToFirebase } from "@Services/FirebaseEvents";
import { AnalyticsEventsName } from "@Services/FirebaseEvents";
import { MessagesActions } from "@Actions/Messages";
import { getBC3Color, isPaidContentAllowed } from "@HelperFunctions";
import { AccessResponseStatus } from "@Constants";
import chatManager from "@Services/ChatManager/ChatManager";
import { useTranslation } from "react-i18next";
import styles from "./style.module.scss";
import { theme } from "@Definitions/Styled";
import useTheme from "../../Hooks/useTheme";

export const FooterContainer: React.FunctionComponent<{
  selected: string;
  setShowLowBalanceModal: (a: boolean) => void;
  user: USER_SESSION;
  setShowNoNameModal: (a: boolean) => void;
  userHasNoName: boolean;
  setShowPaywall: (a: boolean) => void;
}> = ({
  selected,
  user,
  setShowLowBalanceModal,
  setShowNoNameModal,
  userHasNoName,
  setShowPaywall,
}) => {
  const uiTheme = useSelector((state: any) => state.themeReducer.theme);
  const { session } = useSelector((state: IStore) => state.persistState);
  const { newFriendRequest } = useSelector((state: IStore) => state.messages);
  // const { userHasLowBalance } = homeState;
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { accessAllowed, accessBlockReason } = isPaidContentAllowed(user);
  const unreadMessage = chatManager.showBadgeOnAnyUnreadMessage();
  const [newRequest, setNewRequest] = useState(false);
  const [firebaseFriendIds, setFirebaseFriendIds] = useState<string[]>([]);

  return (
    <>
      <div
        className="d-flex align-items-center justify-content-between w-100 px-4 py-1"
        style={{
          height: "68px",
          minHeight: "68px",
          maxHeight: "68px",
          backgroundColor: uiTheme === "dark" ? "#261C52" : "#ffffff",
        }}
      >
        {FooterConfig.map((config, i) => {
          return (
            <Link
              onClick={(e) => {
                if (!session._id && config.name !== "Home") {
                  e.preventDefault();
                  history.push("/login");
                  return;
                }

                if (config.name === "Profile") {
                  // Analytics
                  SendAnalyticsToFirebase(AnalyticsEventsName.PROFILE_VIEW);
                } else if (config.name === "Messages") {
                  if (userHasNoName) {
                    setShowNoNameModal(true);
                    return;
                  }

                  if (accessAllowed) {
                    dispatch(MessagesActions.UpdateUnseenMessageInFotter(0));
                  } else if (
                    accessBlockReason === AccessResponseStatus.LOW_BALANCE
                  ) {
                    e.preventDefault();
                    setShowLowBalanceModal(true);
                    return;
                  } else if (
                    accessBlockReason === AccessResponseStatus.NO_SUBSCRIPTION
                  ) {
                    //setShowPaywall(true);
                    return;
                  }
                  // Analytics
                  SendAnalyticsToFirebase(AnalyticsEventsName.USE_CHAT);
                } else if (config.name === "Home") {
                  // Analytics
                  SendAnalyticsToFirebase(AnalyticsEventsName.TAB_HOME_PRESSED);
                } else if (config.name === "LeaderBoard") {
                  SendAnalyticsToFirebase(
                    AnalyticsEventsName.TAB_LEADER_BOARD_PRESSED
                  );
                } else if (config.name === "TournamentHome") {
                  SendAnalyticsToFirebase(
                    AnalyticsEventsName.TAB_TOURNAMENTS_PRESSED
                  );
                }
              }}
              to={config.routeName}
              key={i}
              className={`text-center menuItemLink position-relative`}
              style={{ width: `${100 / FooterConfig?.length}%` }}
            >
              {selected === config.name && <></>}

              {/*{messagesState.unseenMessagesCount !== 0 && config.name === 'Messages' && <div className="position-absolute"*/}
              <div
                style={{
                  transition: "transform 0.7s",
                  position: "inherit",
                }}
              >
                <StaticImage
                  className="position-relative"
                  src={
                    selected === config.name
                      ? config.imageSelected.src
                      : config.image.src
                  }
                  height={
                    selected === config.name
                      ? config.imageSelected.height
                      : config.image.height
                  }
                  width={
                    selected === config.name
                      ? config.imageSelected.width
                      : config.image.width
                  }
                />
                <div
                  className="mt-2 font-11px titillium-semibold"
                  style={{
                    color:
                      selected === config.name ? theme.colors.green : "#CFCFCF",
                  }}
                >
                  {t(config.tabName)}
                </div>
                {(unreadMessage || newFriendRequest) &&
                  config.name === "Messages" && (
                    <div
                      className={`${styles.chat_notif} position-absolute`}
                      style={{
                        top: "-5px",
                        height: "15px",
                        width: "15px",
                        background: "red",
                        borderRadius: "50px",
                      }}
                    ></div>
                  )}
              </div>
            </Link>
          );
        })}
      </div>
    </>
  );
};

const FooterConfig = [
  {
    name: "Home",
    routeName: "/",
    image: {
      src: "/images/Ico_Home_UnSelected.png",
      height: "24px",
      width: "24px",
    },
    imageSelected: {
      src: "/images/ico_Home@2x.png",
      height: "24px",
      width: "24px",
    },
    tabName: "GAME",
  },

  {
    name: "TournamentHome",
    routeName: "/tournament",
    image: {
      src: "/images/tournament.png",
      height: "24px",
      width: "24px",
    },
    imageSelected: {
      src: "/images/tournament-selected.png",
      height: "24px",
      width: "24px",
    },
    tabName: "TOURNAMENT",
  },
  {
    name: "LeaderBoard",
    routeName: "/leaderboard",
    image: {
      src: "/images/leaderboard.png",
      height: "24px",
      width: "24px",
    },
    imageSelected: {
      src: "/images/leaderboard-selected.png",
      height: "24px",
      width: "24px",
    },
    tabName: "RANK",
  },
  {
    name: "Messages",
    routeName: "/messages",
    image: {
      src: "/images/ico_Chat@2x.png",
      height: "24px",
      width: "24px",
    },
    imageSelected: {
      src: "/images/Ico_Chat_Selected.png",
      height: "24px",
      width: "24px",
    },
    tabName: "CHAT",
  },
  {
    name: "Profile",
    routeName: "/profile",
    image: {
      src: "/images/ico_Friends@2x.png",
      height: "24px",
      width: "24px",
    },
    imageSelected: {
      src: "/images/Ico_Friends_white.png",
      height: "24px",
      width: "24px",
    },
    tabName: "PROFILE",
  },
];
