import React from "react";
import styled from "styled-components";

import { IBackgroundImage } from "./BackgroundImage";

import { getBgImageUrl } from "@Services/UI";

const Container = styled.div<IBackgroundImage.IProps>`
  background-image: ${({ src }) => {
    return src ? getBgImageUrl(src) : "url(unknown)";
  }};
  background-size: ${({ backgroundSize }) => {
    return backgroundSize ? backgroundSize : "cover";
  }};
  background-position: ${({ backgroundPosition }) => {
    return backgroundPosition ? backgroundPosition : "center center";
  }};
  background-repeat: no-repeat;
  width: ${({ width }) => {
    return width ? width : "100%";
  }};
  height: ${({ height }) => height};
  padding-bottom: ${({ paddingBottom }) => {
    return paddingBottom ? paddingBottom : "35.25%";
  }};
  border-radius: ${({ borderRadius }) => {
    return borderRadius ? borderRadius : "2px";
  }};
  z-index: ${({ zIndex }) => {
    return zIndex ? zIndex : "initial";
  }};
  background-color: ${({ backgroundColor }) => {
    return backgroundColor ? backgroundColor : "";
  }};
  display: ${({ display }) => {
    return display ? display : "";
  }};
  align-items: ${({ alignItems }) => {
    return alignItems ? alignItems : "";
  }};
  justify-content: ${({ justifyContent }) => {
    return justifyContent ? justifyContent : "";
  }};
`;

export const BackgroundImage: React.FunctionComponent<
  IBackgroundImage.IProps
> = (props) => {
  return <Container {...props} />;
};
