import React from "react";
import { BackgroundImage } from "@Styled";
import { PLAYERS } from "@Interfaces";
import { truncateString } from "@HelperFunctions";

const circularBorder = (
  isTeamView: boolean,
  index: number,
  chunkedArrayIndex: number,
  isMine: boolean
) => {
  const orangeBorder = "/images/newDesign/orange_frame.png";
  const blueBorder = "/images/newDesign/blue_frame.png";
  if (isTeamView && chunkedArrayIndex === 0) {
    return blueBorder;
  } else if (!isTeamView && isMine) {
    return blueBorder;
  } else return orangeBorder;
};

const namePlaceholderUrl = (
  isTeamView: boolean,
  index: number,
  chunkedArrayIndex: number,
  isMine: boolean
) => {
  const playerUrl = "/images/newDesign/user_name_slot.png";
  const opponentUrl = "/images/newDesign/opponent_name_slot_flip.png";
  if (isTeamView && chunkedArrayIndex === 0) {
    return playerUrl;
  } else if (!isTeamView && isMine) {
    return playerUrl;
  } else return opponentUrl;
};

export const AvatarPlaceholder: React.FunctionComponent<{
  index: number;
  isTeamView: boolean;
  chunkedArrayIndex: number;
  isMine: boolean;
}> = ({ index, children, isTeamView, chunkedArrayIndex, isMine }) => {
  return (
    <BackgroundImage
      src={[circularBorder(isTeamView, index, chunkedArrayIndex, isMine)]}
      backgroundSize="contain"
      backgroundPosition="top center"
      paddingBottom="114.25%"
    >
      {children}
    </BackgroundImage>
  );
};

export const PlayerNamePlaceholder: React.FunctionComponent<{
  user: PLAYERS;
  gameResultScores: any[];
  index: number;
  isTeamView: boolean;
  chunkedArrayIndex: number;
  isMine: boolean;
}> = ({
  user,
  gameResultScores,
  index,
  isTeamView,
  chunkedArrayIndex,
  isMine,
}) => {
  // const wonCount = gameResultScores.length > 0 && user && user._id ? gameResultScores.filter((scores) => {
  //   return (scores.userId === user._id) || (scores.userid === user._id)
  // })[0].won : '';

  const flipPlaceholder = (
    isTeamView: boolean,
    index: number,
    chunkedArrayIndex: number
  ) => {
    // console.log("in filp we got index.ts**",index.ts)
    let flip = false;
    // if(index.ts === 0) {
    //   flip = false
    // } else
    if (index % 2 != 0) {
      flip = true;
    }
    return flip;
  };
  return (
    <div className="position-relative">
      <div
        className="text-start position-absolute text-white titillium-semibold font-12px px-2 overflow-dotted text-center"
        style={{
          // top: "50%",
          // left: "50%",
          // transform: `translate(${user && !isMine ? "-140%" : "-270%"}, 390%)`,

          top: !isMine && !user ? "70px" : "0",
          right: !isMine && !user ? "40px" : "-85px",
        }}
      >
        {/* {user ? truncateString(user.name, 10) : "..."} */}
      </div>
      {/*<div className="text-start text-white titillium-semibold font-18x px-2 overflow-dotted">*/}
      {/*  {gameResultScores.length > 0 && <img src="/images/win_trophy.png" className="px-2" style={{width: "30px"}}/>}*/}
      {/*  <span className="px-2"> {wonCount}</span>*/}
      {/*</div>*/}
    </div>
  );
};
const getresultIconsConfig = (
  isTeamView: boolean,
  chunkedArrayIndex: number,
  isMine: boolean
) => {
  if (isTeamView && chunkedArrayIndex === 0) {
    return 0;
  } else if (!isTeamView && isMine) {
    return 0;
  } else return 1;
};
export const GetResultIcons: React.FunctionComponent<{
  index: number;
  isTeamView: boolean;
  chunkedArrayIndex: number;
  isMine: boolean;
  resultStatus: string | null;
  userRank: number | undefined;
}> = ({
  index,
  isTeamView,
  chunkedArrayIndex,
  isMine,
  resultStatus,
  userRank,
}) => {
  const getCrownStyles = () => {
    let styles: any = {
      position: "absolute",
      bottom: "86%",
    };
    if (index % 2 == 0) {
      styles.left = "1%";
    } else {
      styles.right = "1%";
      styles.transform = "scaleX(-1)";
    }

    return styles;
  };
  const config = getresultIconsConfig(isTeamView, chunkedArrayIndex, isMine);
  return resultStatus == "win" ? (
    <>
      <div
        style={{
          position: "absolute",
          width: "145%",
          top: "-20%",
          left: "-25%",
          zIndex: -1,
        }}
      >
        <BackgroundImage
          src={[
            config == 0
              ? "/images/newDesign/player_glow.png"
              : "/images/newDesign/opponent_glow.png",
          ]}
          backgroundSize="cover"
          backgroundPosition="center"
          paddingBottom="100%"
        />
      </div>
      <div style={getCrownStyles()}>
        <img
          style={{ width: "50%" }}
          src={
            config == 0
              ? "/images/newDesign/Yellow_crown.png"
              : "/images/newDesign/Orange_crown.png"
          }
        />
      </div>
      {!!userRank && (
        <div style={{ position: "absolute", top: "80%", left: "40%" }}>
          <img
            style={{ width: "50%" }}
            src={`/images/newDesign/${userRank}.png`}
          />
        </div>
      )}
    </>
  ) : (
    <div />
  );
  // )
};

export const getTemplateForResult = (playersCount: number) => {
  return {
    title: "1v1",
    isSelectable: false,
    isVisible: true,
    maxPlayerCount: playersCount,
    maxTeamCount: 0,
    minPlayerCount: 2,
    minTeamCount: 0,
    playersInTeam: 0,
  };
};
