import { getThemeString } from "@HelperFunctions";
import { CHANGE_THEME } from "./ThemeConstant";

// @ts-ignore
const initialState = {
  theme: getThemeString(),
};

export const ThemeReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CHANGE_THEME:
      localStorage.setItem("theme", action.data);
      return {
        ...state,
        theme: action.data,
      };
    default:
      return state;
  }
};
