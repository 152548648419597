import { LanguageSelection } from "@Components/LanguageSelection/LanguageSelection";
import { LoginWithFacebook } from "@Components/LoginWithFacebook";
import { IStore } from "@Interfaces";
import React, { lazy, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  AnalyticsEventsName,
  SendAnalyticsToFirebase,
} from "@Services/FirebaseEvents";
import { LoadingGIF } from "@Components/LoadingSpinner/LoadingGIF";
import { GenericPopupModal } from "@Components/Modals/GenericPopupModal";
import "./style.scss";
// import LoginContainer from '@Containers/Log';

const LoginContainer: any = lazy(
  () => import("../../Containers/Login" /* webpackChunkName: "Login" */)
);

const MultiLogin: React.FunctionComponent<{
  setLoginBackground: (a: string) => void;
  fcmToken: string;
  setShowSubscriptionSuccessfull: (a: boolean) => void;
  isFirebaseInitilized: boolean;
  loginWithMobile: boolean;
  setLoginWithMobile: (a: boolean) => void;
}> = ({
  fcmToken,
  setShowSubscriptionSuccessfull,
  isFirebaseInitilized,
  setLoginBackground,
  setLoginWithMobile,
  loginWithMobile,
}) => {
  // const [loginWithMobile, setLoginWithMobile] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loginOptionsSmall, setLoginOptionsSmall] = useState([
    {
      type: "PHONE",
      icon: "twitter_icon",
      text: "CONTINUE_WITH_TWITTER",
      backgroundColor: "#1877F2",
    },
  ]);

  const [loginOptionsBig, setLoginOptionsBig] = useState([
    {
      type: "GOOGLE",
      icon: "google_icon",
      text: "CONTINUE_WITH_GOOGLE",
      backgroundColor: "#fff",
    },
  ]);

  const history: any = useHistory();
  const theme = useSelector((state: any) => state.themeReducer.theme);
  const persistState = useSelector((state: IStore) => state.persistState);
  const { inviRedirectionUrl } = useSelector(
    (state: IStore) => state.persistState
  );
  const {
    session,
    selectedLanguage,
    loginSequence,
    persistFirebaseConfiguration,
  } = persistState;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showSessionOutModal, setShowSessionOutModal] = useState(
    (window as any).userSessionTokenChanged
      ? (window as any).userSessionTokenChanged
      : true
  );
  let loginOrder = loginSequence;
  if (!loginOrder) {
    loginOrder = "PGFT";
  }
  const isCountryMorocco =
    persistFirebaseConfiguration.country.country_code == "ma";
  if (isCountryMorocco) loginOrder = "p";
  useEffect(() => {
    if (session._id) {
      history.push("/");
      return;
    }

    if (isFirebaseInitilized) {
      setIsLoading(false);
      if (loginOrder) {
        if (
          loginOrder.length === 1 &&
          loginOrder[0].toLocaleLowerCase() === "p"
        ) {
          if (!isCountryMorocco) setLoginWithMobile(true);
        }
        let options: any = [];
        loginOrder.split("").forEach((character: string) => {
          if (character.toLocaleLowerCase() === "g")
            options.push({
              type: "GOOGLE",
              icon: "google_icon",
              text: "CONTINUE_WITH_GOOGLE",
              backgroundColor: "#fff",
            });
        });
        if (options.length === 0) {
          setLoginOptionsBig([
            // {
            //   type: "PHONE",
            //   icon: "mobile_icon",
            //   text: "CONTINUE_WITH_PHONE",
            //   backgroundColor: "#FFC700",
            // },
          ]);
          setLoginOptionsSmall([]);
        } else {
          setLoginOptionsBig(options.slice(0, 2));
          setLoginOptionsSmall(options.slice(2, 4));
        }
      }
    }
  }, [isFirebaseInitilized, loginOrder]);

  const getMarkUp = (
    index: number,
    type: string,
    text: string,
    icon: string,
    backgroundColor: string
  ) => {
    if (type === "PHONE") {
      if (index < 2)
        return (
          <div
            key={index}
            className="d-flex
            flex-column
            align-items-center
            mt-3 w-100"
            onClick={() => {
              SendAnalyticsToFirebase(
                AnalyticsEventsName.LOGIN_WITH_PHONE_PRESSED
              );
              setLoginWithMobile(true);
            }}
          >
            <div
              className="d-flex align-items-center
              justify-content-center px-4 py-2
              cursor-pointer social-login-button-width"
              style={{
                borderRadius: "5px",
                background: backgroundColor,
              }}
            >
              <img height="28px" src={`/images/${icon}.png`} />
              <div
                style={{ minWidth: "200px", color: "#fff" }}
                className="text-center font-20px titillium-semibold ml-2"
              >
                {t(text)}
              </div>
            </div>
          </div>
        );
      else return "";
      // <div
      //   key={index}
      //   className="d-flex flex-column
      //   justify-content-center
      //   align-items-center"
      // >
      //   <img
      //     className="px-3 cursor-pointer mt-3 mx-2"
      //     onClick={() => {
      //       SendAnalyticsToFirebase(
      //         AnalyticsEventsName.LOGIN_WITH_PHONE_PRESSED
      //       );

      //       setLoginWithMobile(true);
      //     }}
      //     src={`/images/${icon}.png`}
      //   />
      //   <span className="text-white">{t("PHONE")}</span>
      // </div>
    } else
      return (
        <LoginWithFacebook
          key={index}
          backgroundColor={backgroundColor}
          showShortIcon={index > 1 && true}
          type={type}
        />
      );
  };
  if ((window as any).userSessionTokenChanged && showSessionOutModal) {
    setLoginBackground("bg-login-image");
  }
  return (
    <>
      <LoadingGIF showLoading={isLoading}>
        {!loginWithMobile ? (
          <div className="d-flex flex-column h-100 top-padding">
            <div
              className="d-flex
              flex-column
              mt-5
              justify-content-center
              px-3 w-100"
            >
              <div className="d-flex align-items-center justify-content-between w-100">
                <button
                  className="app-back-button"
                  style={{
                    position: "absolute",
                    top: "20px",
                  }}
                  onClick={() => {
                    if (
                      history.location.state &&
                      history.location.state.prevRoute
                    ) {
                      history.push(history.location.state.prevRoute);
                    } else {
                      history.goBack();
                    }
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    style={{
                      cursor: "pointer",
                      opacity: "0.6",
                    }}
                  >
                    <g
                      data-name="Group 4044"
                      transform={
                        selectedLanguage === "ar"
                          ? "rotate(180 8 8)"
                          : "rotate(0 8 8)"
                      }
                    >
                      <path
                        fill="none"
                        d="M0 0H16V16H0z"
                        data-name="Rectangle 3475"
                      ></path>
                      <g data-name="Group 48" transform="translate(5 2.5)">
                        <path
                          fill="none"
                          stroke={theme === "dark" ? "#FFF" : "#430676"}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="M6 11L0 5.5 6 0"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </button>
                <div
                  className={
                    selectedLanguage === "ar" ? "langDivAr" : "langDiv"
                  }
                >
                  <LanguageSelection
                    selectedLanguage={selectedLanguage}
                    isFromLogin={true}
                  />
                </div>
              </div>
              <img src="" alt="" />
              <div
                className="text-center"
                style={{
                  marginBottom: "64px",
                }}
              >
                <img src="/images/playpallogo.png" width="30%" height="100%" />
              </div>
              <br />
              <div
                className="login-image"
                style={{
                  marginBottom: "20px",
                }}
              ></div>
              {
                <div
                  className={
                    "login-component " +
                    (loginOptionsBig.length === 1 ? "mt-4" : "")
                  }
                >
                  {loginOptionsBig.map((option: any, index) => {
                    return getMarkUp(
                      index,
                      option.type,
                      option.text,
                      option.icon,
                      option.backgroundColor
                    );
                  })}
                </div>
              }
            </div>
          </div>
        ) : (
          <LoginContainer
            setLoginBackground={setLoginBackground}
            fcmToken={fcmToken}
            setShowSubscriptionSuccessfull={setShowSubscriptionSuccessfull}
            isFirebaseInitilized={isFirebaseInitilized}
            loginSequence={[
              ...loginOptionsBig.filter((ls) => ls.type !== "PHONE"),
              ...loginOptionsSmall.filter((ls) => ls.type !== "PHONE"),
            ]}
            isCountryMoroco={isCountryMorocco}
            setLoginWithMobile={(flag: boolean) => {
              if (!flag) {
                setLoginBackground("bg-login-image");
              }
              setLoginWithMobile(flag);
            }}
          />
        )}
      </LoadingGIF>
      {(window as any).userSessionTokenChanged && showSessionOutModal && (
        <GenericPopupModal
          title={t("LOGGED_OUT")}
          description={t("LOGGED_OUT_MSG")}
          showSingleButton={true}
          showInfoIcon={true}
          firstButtonText={t("OK")}
          firstButtonCallback={() => {
            (window as any).userSessionTokenChanged = false;
            setShowSessionOutModal(false);
          }}
        />
      )}
    </>
  );
};

export default MultiLogin;
