import React, { useState } from "react";
import ReactDOM from "react-dom";

import { AnimatePopup, ParagraphText, PrimaryButton } from "@Styled";
import { theme } from "@Definitions/Styled";
import { CanelTermsWarning } from "./CancelTermsWarning";
import { SendAnalyticsToFirebase } from "@Services/FirebaseEvents";
import { AnalyticsEventsName } from "@Services/FirebaseEvents";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export const TermsConditionsModal: React.FunctionComponent<{
  onRejectClick: () => void;
  onAcceptClick: () => void;
}> = ({ onRejectClick, onAcceptClick }) => {
  const [showIgnoreWarning, setShowIgnoreWarning] = useState(false);
  const { t } = useTranslation();

  const themeType = useSelector((state: any) => state.themeReducer.theme);

  const viewPort = document.getElementById("view-port-container");

  return ReactDOM.createPortal(
    <AnimatePopup animateIn={true} background="rgb(0,0,0,0.7)" zIndex={1}>
      {showIgnoreWarning && (
        <CanelTermsWarning
          onYesClick={() => {
            setShowIgnoreWarning(false);
            onRejectClick();
          }}
          onNoClick={() => {
            setShowIgnoreWarning(false);
          }}
        />
      )}
      <div
        className="d-flex flex-column w-100 mx-5 py-4 px-3"
        style={{
          borderRadius: "12px",
          backgroundColor:
            themeType === "dark" ? theme.colors.primary : "white",
        }}
      >
        {/* <div className="d-flex justify-content-end align-items-center cursor-pointer"
                 onClick={() => {
                 setShowIgnoreWarning(true);
                 }}>
                 <img height="18px" width="18px" src="/images/cancel.png" />
                 </div> */}
        <div
          className="pt-2"
          style={{
            borderBottom: `0.5px solid ${
              themeType === "dark"
                ? "rgba(255, 255, 255, 0.3)"
                : "rgba(0, 0, 0, 0.1)"
            }`,
            color: themeType === "light" ? theme.colors.primary : "white",
          }}
        >
          <ParagraphText className="d-flex font-24px">
            {t("TERMS")} {t("CONDITIONS")}
          </ParagraphText>
        </div>
        <br />
        <div
          className="d-flex flex-column font-12px terms-conditions"
          style={{
            color: themeType === "dark" ? "white" : theme.colors.primary,
          }}
        >
          {/* <div className="d-flex">{t("TERMS_1")}</div>
          <div className="d-flex">{t("TERMS_2")}</div>
          <div className="d-flex">{t("TERMS_3")}</div>
          <div className="d-flex">{t("TERMS_4")}</div>
          <div className="d-flex">{t("TERMS_5")}</div>
          <div className="d-flex">{t("TERMS_6")}</div> */}
          <div
            className="d-flex"
            style={{
              color: themeType === "dark" ? "white" : theme.colors.primary,
            }}
          >
            {t("TERMS_AND_CONDITIONS_TEXT")}
          </div>
        </div>
        <br />
        <div className="d-flex flex-column align-items-center justify-content-center">
          <PrimaryButton
            className="font-20px titillium-semibold w-100 text-white"
            backgroundColor={theme.colors.green}
            borderRadius="4px"
            onClick={() => {
              onAcceptClick();

              // Analytics
              SendAnalyticsToFirebase(
                AnalyticsEventsName.ON_TERMS_N_CONDITION_ACCEPTED
              );
            }}
          >
            {t("ACCEPT")}
          </PrimaryButton>
          <p
            className="font-20px titillium-semibold mt-2"
            style={{
              cursor: "pointer",
              textDecoration: "underline",
              color: themeType === "light" ? theme.colors.primary : "white",
            }}
            onClick={() => onRejectClick()}
          >
            {t("DECLINE")}
          </p>
          <br />
          {/* <div className="cursor-pointer font-16px" style={{ color: "#B9B9B9" }}
                     onClick={() => {
                     setShowIgnoreWarning(true);

                     // Analytics
                     SendAnalyticsToFirebase(AnalyticsEventsName.ON_TERMS_N_CONDITION_DECLINE);
                     }}>
                     {t("DECLINE")}
                     </div> */}
        </div>
      </div>
    </AnimatePopup>,
    viewPort ? viewPort : document.body
  );
};
